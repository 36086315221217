import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Nav/nav.js"
import "./App.css";
import "./style.css"
import Event from "./components/event/event"
import AuthService from "./services/auth.service";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import Code from './components/code/code'
import MarketPalace from "./components/market/market.js";
import PublicAssignment from "./components/Assignment/publicAssin";
import Assignment from './components/Assignment/assignment'
import Assignments from './components/Assignment/assignments'
import Register  from "./components/Register/Register.js";
import SignUp from './components/Signup/signup'
import Login from './components/Login/Login'
import Footer from './Layout/footer'
// import 

// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [loginNav,setLoginNav]= useState(false);
  const [sidenav,setSidenav]= useState(false);
  const [profile,setProfile] = useState(false)

  useEffect(() => {
    const user = AuthService.getCurrentUser();
     
    if (user) {
      setCurrentUser(user);
      setLoginNav(true)
      setShowModeratorBoard(null);
      // setShowModeratorBoard(user.username.includes("ROLE_MODERATOR"));
      // setShowAdminBoard(user.username.includes("ROLE_ADMIN"));
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);


  const showProfile=(e)=>{
    // e.preventDefault(); 
    setProfile(!profile)

  }

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  

 const  sendtoggleMenu=(data)=>{
    // console.log("data-------email sending need to be check------>",data)
    setSidenav(!data)
   }


  const pages = ['about', 'contact', 'sitemap'];
  return (
    <div>
          <nav className="navbar navbar-expand navbar-dark " style={{background:"rgb(255, 255, 255)",boxShadow:"rgba(0, 0, 0, 0.1) 0px 2px 4px 0px"}}>
              <Link to={"/"} className="navbar-brand-bt">
                 <img src="https://res.cloudinary.com/codepally/image/upload/v1669803366/UFUON%20LOGO%20PNG/ufuon%20logo%20playstore/uf2_iiwvue.png" className="nav-logo" />
            </Link>
            <div className="navbar-nav mr-auto">

                <li className="nav-item" style={{marginRight:'2%'}}>
                   
                </li>

                  {showAdminBoard && (
                    <li className="nav-item">
                      <Link to={"/admin"} className="NKmQR">
                        Admin Board
                      </Link>
                    </li>
                  )}

            </div>
            <div className="app-left-mobile903">
            
                
                <div className="navbar-nav ml-auto nav-mobile903">
                  <li className="nav-item" style={{position:'relative',right:'8%'}}>
                      <a href={"/class"} class="nav-class-buttom mobile903" >
                       Class
                      </a>
                  </li>
                   <li className="nav-item" style={{position:'relative',right:'5%'}}>
                   <Link to={"/code"} class="nav-create-buttom mobile903" >
                   Enter code
                   </Link>
                 </li>
                {
                //   <li className="nav-item" style={{position:'relative',right:'5%'}}>
                //   <a href={"https://www.ufuon.com/contact"} className="NKmQR">
                //   Contact Us
                //   </a>
                // </li>
                }
                
              
                </div>
            </div>

        </nav>
      <div >
      
        <Routes>
          <Route exact path={"/"} element={<Code />} />
          <Route exact path={"/home"} element={<Home />} />
          <Route exact path={'/code'}  element={<Code />} />
          <Route exact path={'/class'}  element={<Event/>} />
          <Route exact path={'/class-register'}  element={<Register/>} />
          <Route  path="/asssignments" element={currentUser === undefined ? <Navigate to="/code" /> : <Assignments /> }/>
          <Route exact path="/auth/signup" element={<SignUp  />} />
          <Route exact path="/auth/login" element={<Login  />} />
          <Route exact path="/assignment-detail/:id" element={<PublicAssignment profile={currentUser} />} />
          <Route exact path="/market-palace" element={<MarketPalace/>}/>
          <Route path="/assignment/:id"  element={<Assignment />} />
          <Route path="/mod" element={<BoardModerator />} />
          <Route path="/admin" element={<BoardAdmin />} />
        </Routes>
      </div>

      {/* <AuthVerify logOut={logOut}/> */}
      <Footer/>

    </div>
  );
};

export default App;
