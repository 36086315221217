import React from "react";
export default  function Footer () {
  return (
         <div style={{marginTop:'3%'}}> 
          <div class="container-fluid text-white mt-5 py-5 px-sm-3 px-md-5" 
                style={{backgroundColor:"#46178F",}} >
                <div class="row pt-5">
                <div class="col-lg-3 col-md-6 mb-5">
                    <a  href="" class="navbar-brand font-weight-bold text-primary m-0 mb-4 p-0"
                    style={{fontSize: "40px", lineHeight: "40px"}}
                    >
                    <i class="flaticon-043-teddy-bear"></i>
                    <span class="text-white">ABOUT US</span>
                    </a>
                    <p style={{fontSize:"12px"}}>
                    Ufuon is a game based learning platform that helps students
                    and teachers be the best they can be. For teachers, ufuon 
                    helps connect them to thousand of students who can enjoy 
                    the learning content they create that  they might not have 
                    access to in a  traditional classroom.  
                    For students, ufuon provide an interesting way for them to learn, 
                    learning on ufuon is optimized to mimic game playing, to keep the 
                    interest of the students longer than a class based learning.
                    </p>
                    <div class="d-flex justify-content-start mt-4">
                    <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0"
                        style={{width: "38px", height: "38px"}} target="_blank"
                        href="https://x.com/ufuon_edu"
                        ><i class="fab fa-twitter"></i
                    ></a>
                    <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                        style={{width: "38px", height: "38px"}}
                        href="https://www.facebook.com/Ufuon1-111894188419377"
                        ><i class="fab fa-facebook-f"></i
                    ></a>
                    <a  class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                        style={{width: "38px", height: "38px"}}
                        href="#"
                        ><i class="fab fa-linkedin-in"></i
                    ></a>
                    <a class="btn btn-outline-primary rounded-circle text-center mr-2 px-0" target="_blank"
                        style={{width: "38px", height: "38px"}}
                        href="https://www.instagram.com/ufuon_edu/"
                        ><i class="fab fa-instagram"></i
                    ></a>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-5"  style={{paddingTop:'1%'}}>
                <h3   class=" mb-4" style={{marginLeft:'10%',color:'#fff'}}>Quick Links</h3>
                <div  class="d-flex flex-column justify-content-start" style={{marginLeft:'15%'}}>
                    <a class="text-white mb-2" href="/"
                    ><i class="fa fa-angle-right mr-2"></i>Home</a>
                    <a href="https://store.ufuon.com" target={'_blank'} class="text-white mb-2"> <i class="fa fa-angle-right mr-2"></i> Ufuon store</a>
                    <a class="text-white mb-2" href="/about"
                    ><i class="fa fa-angle-right mr-2"></i>About Us</a>
                    <a class="text-white" href="/contact"  ><i class="fa fa-angle-right mr-2"></i>Contact Us</a>

                    <a class="text-white mb-2" href={"https://www.ufuon.com/terms"} >
                    <i class="fa fa-angle-right mr-2"></i>    Terms
                    </a>
                    
                </div>
                </div>
                
                
                <div class="col-lg-3 col-md-6 ">
                    <h3 class=" mb-4" style={{color:'#fff'}}>Contact us</h3>
                    <b>Do you have questions or in need of further clarification? Speak to a Counsellor.</b>
                    <img src="https://cdn4.iconfinder.com/data/icons/people-avatars-8/256/PEOPLE_ICON-21-512.png" style={{position:'relative',bottom:'10%'}} />
                </div>

                <div class="col-lg-3 col-md-6 mb-5">
                <h3 class="text-primary mb-4">Get In Touch</h3>
                <div class="d-flex">
                    <h4 class="fa fa-map-marker-alt text-primary"></h4>
                    <div class="pl-3">
                    <h5 class="text-white">Address</h5>
                    <p>59C Old Aba Road, Rumuobiakani, Port Harcort, Rivers State Nigeria.</p>
                    </div>
                </div>
                <div class="d-flex">
                    <h4 class="fa fa-envelope text-primary"></h4>
                    <div class="pl-3">
                    <h5 class="text-white">Email</h5>
                    <p>hello.ufuon.com@gmail.com</p>
                    </div>
                </div>
                <div class="d-flex">
                    <h4 class="fa fa-phone-alt text-primary"></h4>
                    <div class="pl-3">
                    <h5 class="text-white">Phone</h5>
                    <p>+234 8139 582 152</p>
                    </div>
                </div>
                </div>
                </div>
                <div
                class="container-fluid pt-5"
                style={{borderTop: "1px solid rgba(23, 162, 184, 0.2)"}}
                >
                <p class="m-0 text-center text-white">
                    &copy;
                    <a class="font-weight-bold" style={{color:'white'}} href="#">Ufuon</a>.
                    All Rights Reserved.

                {
                    //<!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
                }
                    Designed by 
                    <a class=" font-weight-bold"style={{color:"white", marginLeft:'5px'}} href="https://codepally.com"
                    >Codepally</a>
                    
                
                
                </p>
                </div>
            </div>
         </div>   
     );
}
