import React, {Component,useState,useRef,useEffect,useCallback} from 'react';
import AuthService from "../../services/auth.service";
import { truncateText } from "truncate-strings";

import {Route, Link, Routes, useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDropzone } from "react-dropzone"
import axios from 'axios';
// import {API_BASE_URL,URL_EXAM_END, API_BASE_LOCAL,ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import './assignment.css';

const Assignment = (props) => {
   // const id = props.match.params;
   const params = useParams();
  const URL_EXAM_END = "https://exam-end.herokuapp.com";
  const currentUser = AuthService.getCurrentUser();
  const [userId,setUserId] = useState(null)
  const [publish,setPublish]= useState(false);
  const [errorColor,setErrorColor] =useState(false);
  const [AlertNotice ,setAlertNotice]=useState(false);
  const [loadPost,setLoadPost]=useState(false);
  const [alertMessage ,setAlertMessage]=useState(null);
  const [isLoaded ,setIsLoaded]=useState(false);
  const [hideModal,setHideModal]=useState(false);
  const [assignments,setAssignments]= useState([])
  const [books,setBooks]= useState([])
  const [assignmentData,setAssignmentData]= useState(null)
  const [assignment,setAssignment]= useState(null)
  const [lessonModal,setLessonModal]=useState(false);
const [lessons,setLessons]= useState([])
  const [bookModal,setBookModal]=useState(false);
  const [show,setShow] = useState({page:1,title:'Summary'})
  const [lessonData,setLessonData] = useState(null)
 const [confirm,setConfirm] = useState({page:1,title:'show data'})
 const [confirmBook,setConfirmBook] = useState({page:1,title:'show data'})
 const [bookData,setBookData] = useState(null)
 const [deleteBook,setDeleteBook]= useState(null)
 const [loading , setLoading]= useState(false)
 const [imageLoader, setImageLoader]= useState(false)
 const [pdfImage,setPdfImage]= useState(null)
 const [pdfBook,setPdfbook]= useState(null)
 const [pdf,setPdf]= useState(null)
 const [uploadShow,setUploadShow]= useState({page:1,title:'show data'})
 const [title , setTitle] = useState(null)
 const [titleBookError , setTitleBookError] = useState(null)
 const [message , setMessage] = useState(null)
 const [error , setError] = useState(null)
 const [authors, setAuthors] = useState(null)
 const [authorsBookError, setAuthorsBookError] = useState(null)
  const [language, setLanguage] = useState(null)
  const [languageBookError, setLanguageBookError] = useState(null)
 const [description,setDescription] = useState(null)
 const [descBookError,setDescBookError] = useState(null)
 const [quizs,setQuizs] = useState([])
 const [quizsData,setQuizData] = useState(null)
 const [quizModal,setQuizModal] = useState(false)
 const [quizShow,setQuizShow] = useState({page:1,title:'show data'})
 const [deleteQuizModal,setDeleteQuizModal]= useState(false)
 const [avatar, setAvatar] = useState(null);
 const [examId, setExamId] = useState(null);
 const [service, setService] = useState(null);
 const [ imageLogo,setImageLogo]= useState(null)
 const [Logo , setLogo] = useState(null)
 const [bookBanner ,setBookBanner] = useState(null)
 const [bookImage ,setBookImage] = useState(null)
 const [editData, setEditData] = useState(false)
 const [showForm,setShowForm] = useState(false)
 const [categories ,seCategories] = useState(null)
 const [ editIf  ,setEditIf]= useState(false)
 const [loadingPage,setLoadingPage]= useState(false)
 const [step,setStep]= useState({page:1,title:'show data'})
 

  
  
 
   
  useEffect(()=> {
   setLoadingPage(true)
   // props.error404(false)
   // props.updateTitle('course')
   // props.error404(false)
   // console.log("data ------article----->",params.id)
   
   setUserId(params.id)
   const fetchData = async () => {
       fetch(`${URL_EXAM_END}/assignment/${params.id}`)
       .then(res => res.json())
       .then(
         (res) => {
         //   console.log("data ------setAssignments----->",res)
             if(res.status === false){
               // setIsLoaded(false);
             }else{
               setAssignment(res.data)
               setLoadingPage(false)
             }

         },
         (error) => {
           // setIsLoaded(false);
           // setError(error);
         } )

         fetch(`${URL_EXAM_END}/books/user/${currentUser._id}`)
         .then(res => res.json())
         .then(
            (res) => {
               
               if(res.status === false){
                  // setIsLoaded(false);
               }else{
                  setBooks(res.data)
               }
            },
            (error) => {
               // setIsLoaded(false);
               // setError(error);
            } )

         fetch(`${URL_EXAM_END}/events/user/${currentUser._id}`)
         .then(res => res.json())
         .then(
            (res) => {
             
               if(res.status === false){
                  // setIsLoaded(false);
               }else{
                  setLessons(res.data)
               }
            },
            (error) => {
               // setIsLoaded(false);
               // setError(error);
            } )

            fetch(`${URL_EXAM_END}/questions/user/${currentUser._id}`)
            .then(res => res.json())
            .then(
               (res) => {
              
                  if(res.status === false){
                     // setIsLoaded(false);
                  }else{
                     setQuizs(res.data)
                  }
               },
               (error) => {
                  // setIsLoaded(false);
                  // setError(error);
               } )
   
           
         
 }  
   fetchData();
},[])


const showLessonData = (data)=>{
   setBookData(data)
  setConfirmBook({page:2,title:'show data'})
}

const uuidv4=(digits)=> {
     
   let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
   let uuid = [];
   for (let i = 0; i < digits; i++) {
       uuid.push(str[Math.floor(Math.random() * str.length)]);
   }
   return uuid.join('');
 
 }
 
 

const clickEventListener = () =>{
  
        setIsLoaded(true)
        const data = {
         lesson:lessonData._id
     }

      const requestOptions = {
          method: 'POST',
            headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
             //   'x-access-token': Auth.getToken()
           },
           body: JSON.stringify(data)
          };
          return fetch(`${URL_EXAM_END}/assignment/add-lesson/${assignment._id}`, requestOptions )
              .then(res => res.json())
              .then(res => {
             
                if(res.status  ===true){
                  setIsLoaded(false)
                  toast("Save successfully ")
                }else{
                  setIsLoaded(false)
                  toast("Not Save")
                }
              // console.log("params.ress ----------------------->",res)
           
           }) .catch((error) => {
            console.error('Error:', error);
            setIsLoaded(false)
            toast("Not Save")
        });
  
 }

const showBookData =(data)=>{
   console.log('show book data',data)
   setBookData(data)
   setConfirmBook({page:2,title:'show data'})
}

const clickBookListener = async () => {
try{
   const b = await assignment.books.map(e => e._id).includes(bookData._id)
   setIsLoaded(true)
   if(b){
     
      toast("This book is already added")
      setIsLoaded(false)
     } else{

         const data = {
       book:bookData._id
      }
    
      const requestOptions = {
         method: 'POST',
            headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
            //   'x-access-token': Auth.getToken()
            },
            body: JSON.stringify(data)
         };
         return fetch(`${URL_EXAM_END}/assignment/add-books/${assignment._id}`, requestOptions )
               .then(res => res.json())
               .then(res => {
               // console.log("params.ress ----------------------->",res)
               if(res.status  ===true){
                  setIsLoaded(false)
                  toast("Was successfully added")
                  setAssignment(res.data)
                  setConfirmBook({page:1,title:'show data'})
               }else{
                  setIsLoaded(false)
                  toast("Not Save")
               }
               // console.log("params.ress ----------------------->",res)
            
            }) .catch((error) => {
            console.error('Error:', error);
            setIsLoaded(false)
            toast("Not Save")
         });
     }
  
   }catch(error){

   }
}

const BackToAssignment = ()=>{
   // setBookData(data)
   setConfirmBook({page:1,title:'show data'})
}

const showBookDelete = (data) =>{
    setBookData(data)
    setDeleteBook(true)
    setConfirmBook({page:1,title:'show data'})
}

const RemoveBookFromLesson = ()=>{
   setIsLoaded(true)
         const data = {
        book:bookData._id
      }
      // console.log("data----- tilte check=------->", data  )
      const requestOptions = {
         method: 'POST',
            headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
            //   'x-access-token': Auth.getToken()
            },
            body: JSON.stringify(data)
         };
         return fetch(`${URL_EXAM_END}/assignment/remove-books/${assignment._id}`, requestOptions )
               .then(res => res.json())
               .then(res => {
          
               if(res.status  ===true){
                  setIsLoaded(false)
                  setDeleteBook(false)
                  toast("Was successfully remove ")
                  setAssignment(res.data)

               }else{
                  setIsLoaded(false)
                  toast("Not Save")
               }
          
            
            }) .catch((error) => {
            console.error('Error:', error);
            setIsLoaded(false)
            toast("Not Save")
         });
}

const getDayOfTheMon =(data)=>{
   var dayName =['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
   var day = dayName[new Date(data).getDay()];
   // console.log("first one------>",new Date())
   // console.log("second one------>",new Date(data).getDay())
     return day
 }

 const getMonth =(data)=>{
    var day = parseInt(data) - 1
   //  console.log("give me day----------->",day)
   var month 
   var dayName =['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];
   //  console.log("month-------------->",dayName[day] )
   return month =  dayName[day]

 }

 const formatAMPM =(date) => {
   var hours = date.getHours();
   var minutes = date.getMinutes();
   var ampm = hours >= 12 ? 'pm' : 'am';
   hours = hours % 12;
   hours = hours ? hours : 12; // the hour '0' should be '12'
   minutes = minutes < 10 ? '0'+minutes : minutes;
   var strTime = hours + ':' + minutes + ' ' + ampm;
   return strTime;
 }



   const [myFiles, setMyFiles] = useState([])

   const onDrop = useCallback(acceptedFiles => {
     setMyFiles([...myFiles, ...acceptedFiles])
   })
 
   const { getRootProps, getInputProps } = useDropzone({
     onDrop, multiple: false
   })
 
  
  
  
    const removeFile = file => () => {
      const newFiles = [...myFiles]
      newFiles.splice(newFiles.indexOf(file), 1)
      setMyFiles(newFiles)
    }
  
    const removeAll = () => {
      setMyFiles([])
    }

    const addFileUpload= ()=>{
      setUploadShow({page:2,title:'show data'})
    }

 


     const SendData = () =>{
       if(title === " " || title === null  ){
       }else{
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(regex.test(title) === false){
            console.log(false)
            toast.error('Email is not valid');
            }else{
            console.log(true)
              setLoading(true)
            //   console.log('data-------------->',bucket)
              const uploaders = myFiles.map(image => {
                const formData = new FormData();
                console.log('done image---->',  image);
                formData.append('file', image);
                formData.append('product_images', true);
               //  formData.append('email', email);
              //   formData.append('product_id',  this.state.id);
                const options = {
                    method: 'POST',
                    headers: { 
                        'content-type': 'multipart/form-data',
                    'access-control-allow-origin': '*',
                  //   'Authorization': Auth.getToken(), 
                    },
                url:test+"/send/files",
                  };
              // Make an AJAX upload request using Axios
                return  axios.post(test+'/send/files', formData, options.headers)
                    .then(response => {
                      
                        console.log("response", response.data)
                          if(response.data.status ===  "success"){
                            setLoading(false)
                           //  setUserPage({page:1,title:'home'})
                           //  setUploadPage({page:1,title:'home'})
                            toast.success('File uploaded successfully');
                            setMyFiles([])
                            }else{
                              toast.error('File not uploaded ');
                            }
                      
                          })
                        
                      });
              
                // Once all the files are uploaded 
                  axios.all(uploaders).then(() => {
                  
              }).catch(err =>   toast.error(err));
         }
       }
     

     }


    const files = myFiles.map(file => (
      <li key={file.path} style={{ marginBottom:'1%'}}>
        {file.path} - {file.size} bytes{" "}
        <a class="button is-small is-danger jb-modal" style={{ float:"right",}} onClick={removeFile(file)}>
            <span class="icon is-small">
            <i class="fas fa-trash-alt"></i>
            </span>
        </a>
      </li>
    ))




    const submitBookData = ()=>{
      getInfoData()
      setLoading(true)
      // setExamId(uuidv4(39))
      // console.log("my files myFiles ------->",myFiles[0])
         const data = new FormData()
         data.append('file', myFiles[0])
         data.append("UPLOADCARE_STORE","auto")
         data.append("metadata[subsystem]", "uploader")
         data.append("metadata[pet]", "cat")
         data.append("UPLOADCARE_PUB_KEY", "9b53a5c119f333856a6d");
         fetch(`https://upload.uploadcare.com/base/`, {
         method: "post",
         body: data
         }).then(res => res.json())
         .then(data => {
            console.log("An Error Occured While Uploading",data,)
            
            if(data.file === undefined || data.file === null){
      
                  // setImageLoader(false)
            }else{
               
         
               // setImageLoader(false)
               // setBookBanner(data.file)
               getInfoData(data.file)
            }
            
            // setPhoto(data.secure_url)
         }).catch(err => {
            console.log("An Error Occured While Uploading judge",err)
         })
    }


    const getInfoData = (data)=>{
       
            const dataSend ={
            type:"uploadcare",
            name:myFiles[0].name,
            version_id:data,
            fileType:myFiles[0].type
            }
            const newData = {
            book:dataSend,
            authors:authors,
            language:language,
            description:description,
            user:currentUser._id,
            name:title,
            title:title,
            assignment:assignment._id,
            Id:uuidv4(39)
            }
            
            const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newData),
            };
            console.log("requestOptions",requestOptions)
            fetch(`${URL_EXAM_END}/books/web-create`, requestOptions)
            .then(res => res.json())
            .then((res)=>{
            console.log("res", res)
               if(res.status === true){
                  setLoading(false)
                  assignment.books.push(res.data)
                  
               }else {
                  setLoading(false)
               }
            }).catch((error) => {
               setLoading(false)
               console.error('Error:', error);
            });
    
    }


 const textTitle =  (val) => {
   // setNameBook
   if( val.length !== 0 ) {
     setTitle(val)
       const reg = /^(\d*)(\.(\d*))?$|^$/
       if (reg.test(val) === true){
        
           setTitleBookError(true)
           setError(true)
           setMessage('Input not  valid')
       }
       else{
           
            setTitleBookError(false)
            // setMessageNameBook(null)
            setError(false)
         setMessage('Input not  valid')
       }
 
   } else {
      setTitle(val)
   }
 }

  const textAuthors = (val) => {
   // setNameBook
   if( val.length !== 0 ) {
     setAuthors(val)
       const reg = /^(\d*)(\.(\d*))?$|^$/
       if (reg.test(val) === true){
        
           setAuthorsBookError(true)
           setError(true)
           setMessage('Input not  valid')
       }
       else{
       
            setAuthorsBookError(false)
            // setMessageNameBook(null)
            setError(false)
         setMessage('Input not  valid')
       }
 
   } else {
      setTitle(val)
   }
 }

 const textLanguage= (val) => {
   // setNameBook
   if( val.length !== 0 ) {
     setLanguage(val)
       const reg = /^(\d*)(\.(\d*))?$|^$/
       if (reg.test(val) === true){
          
           setLanguageBookError(true)
           setError(true)
           setMessage('Input not  valid')
       }
       else{
           
           setLanguageBookError(false)
            // setMessageNameBook(null)
            setError(false)
         setMessage('Input not  valid')
       }
 
   } else {
      setLanguage(val)
   }
 }
  
 const textDescription =(val) => {
   // setNameBook
   if( val.length !== 0 ) {
     setDescription(val)
       const reg = /^(\d*)(\.(\d*))?$|^$/
       if (reg.test(val) === true){
          
           setDescBookError(true)
           setError(true)
           setMessage('Input not  valid')
       }
       else{

            setAuthorsBookError(false)
            // setMessageNameBook(null)
            setError(false)
         setMessage('Input not  valid')
       }
 
   } else {
      setDescription(val)
   }
 }

 const nexStep = () =>{
   
      if(title === '' || title === true ||title === null || title.length === 0 ){
         
         setTitleBookError(true)
         setError(true)
         setMessage('Input not  valid')
         return
   }

   if(authors === '' || authors === true ||authors === null || authors.length === 0 ){
         
      setAuthorsBookError(true)
      setError(true)
         setMessage('Input not  valid')
         return
   }

   if(language === '' || language === true ||language === null || language.length === 0 ){
      setLanguageBookError(true)
      setError(true)
      setMessage('Input not  valid')
       return
   }

   if(description === '' || description === true ||description === null || description.length === 0 ){
      setDescBookError(true)
      setError(true)
         setMessage('Input not  valid')
         return
   }
  

   setStep({page:2,title:'upload'})
 }
  
 const showQuizData = (data) =>{
   setQuizData(data)
   setQuizShow({page:2,title:'show data'})
 }

 const AddQuizListener  = async () => {
   
   setIsLoaded(true)
   try{
      const b = await assignment.question.map(e => e._id).includes(quizsData._id)
      setIsLoaded(true)
      if(b){
       
         toast("This quiz is already added")
         setIsLoaded(false)
        } else{
         
            const data = {
            quiz:quizsData._id
         }
       
         const requestOptions = {
            method: 'POST',
               headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
               //   'x-access-token': Auth.getToken()
               },
               body: JSON.stringify(data)
            };
            return fetch(`${URL_EXAM_END}/assignment/add-quiz/${assignment._id}`, requestOptions )
                  .then(res => res.json())
                  .then(res => {
                
                  if(res.status  ===true){
                     setIsLoaded(false)
                     toast("Was successfully added")
                     setAssignment(res.data)
                     setConfirmBook({page:1,title:'show data'})
                  }else{
                     setIsLoaded(false)
                     toast("Not Save")
                  }
                
               
               }) .catch((error) => {
               console.error('Error:', error);
               setIsLoaded(false)
               toast("Not Save")
            });
        }
     
      }catch(error){
   
      }
   }

   const showQuizDelete =(data)=>{
      setDeleteQuizModal(true)
      setQuizData(data)
   

   }

   const RemoveQuizFromAssignment = ()=>{
 
         setIsLoaded(true)
            const data = {
               quiz:quizsData._id
         }
        
         const requestOptions = {
            method: 'POST',
               headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
               //   'x-access-token': Auth.getToken()
               },
               body: JSON.stringify(data)
            };
            return fetch(`${URL_EXAM_END}/assignment/remove-quizs/${assignment._id}`, requestOptions )
                  .then(res => res.json())
                  .then(res => {
               
                  if(res.status  ===true){
                     setIsLoaded(false)
                     setDeleteQuizModal(false)
                     toast("Was successfully remove ")
                     setAssignment(res.data)

                  }else{
                     setIsLoaded(false)
                     toast("Not Save")
                  }
               
               }) .catch((error) => {
               console.error('Error:', error);
               setIsLoaded(false)
               toast("Not Save")
            });
   }

    return(
      loadingPage?
      <div className="assignment-full">
         <div className="assignment-loading">
            <button class="button is-loading">Loading</button>
         </div>
      </div>
      :
      <div className="assignment-full">
      <ToastContainer />
         <div class="columns">
            <div class="column is-three-quarters">
               <div className='assignment-left-hander'>
               {
                  assignment === null?
                  null
                  :
                  <div className='assignment-right'>
                     <button class="button is-small" onClick={()=>setLessonModal(true)}>Connect Lesson</button>
                     <button class="button is-small" onClick={()=>setBookModal(true)}>Add books</button>
                     <button class="button is-small" onClick={()=>setQuizModal(true)}>Add quiz</button>
                  </div>
               }
                  <h2><b>Report</b></h2>
                  {
                     assignment === null?
                     null:
                     <h4><b>{assignment.name}</b></h4>
                  }
                  {
                     assignment === null?
                     null:
                     <h6>Code <b>{assignment.codeId}</b></h6>
                  }
                  {
                     assignment === null?
                     null
                     :
                     <div class="tabs assignment-tab">
                        
                           <ul>
                              <li class={show.page === 1 ? "is-active" : null}><a onClick={()=>setShow({page:1,title:'Summary'})}>Summary</a></li>
                              <li class={show.page === 2 ? "is-active" : null}><a  onClick={()=>setShow({page:2,title:'Players'})}>Players({assignment.player.length + assignment.students.length})</a></li>
                              <li class={show.page === 3 ? "is-active" : null}><a  onClick={()=>setShow({page:3,title:'Questions'})}>Questions({assignment.question.length})</a></li>
                              <li class={show.page === 4 ? "is-active" : null}><a  onClick={()=>setShow({page:4,title:'Books'})}>Document({assignment.books.length})</a></li>
                           </ul>
                     </div>
                  }
               </div>
            </div>
            <div class="column">
              <div className="assignment-left-body">
                  {
                     assignment === null ? 
                     null
                     :
                     <div>
                     {
                        assignment.done  === true? 
                        <button class="button is-danger is-outlined is-small" >
                              <span>Finish </span>
                        </button>
                        :
                        <button class="button is-primary is-outlined is-small">Waiting</button>
                        
                     }
                   </div>
                  }
                   
                     {
                        assignment === null?
                        null
                        :
                        <div >
                        {
                           assignment.end === null || assignment.end === undefined?
                           <div class='assignment-left-time'>
                           <p> Create date: {getDayOfTheMon(assignment.createdAt)}, {getMonth(new Date(assignment.createdAt).getMonth())}, {formatAMPM(new Date(assignment.createdAt))}</p>
                           </div>
                           :
                           <div class='assignment-left-time'>
                           <p> Start Date: {getDayOfTheMon(assignment.createdAt)}, {getMonth(new Date(assignment.createdAt).getMonth())}, {formatAMPM(new Date(assignment.createdAt))}</p>
                           </div>
                        }
                        
                        
                        </div>
                     }
                     {
                        assignment === null?
                        null
                     :
                     <div >
                     {
                        assignment.end === null ||  assignment.end === undefined?
                        null
                        :
                        <div class='assignment-left-time'>
                     
                        <p> End date: {getDayOfTheMon(assignment.end.day)}, {getMonth(new Date(assignment.end.day).getMonth())}, {formatAMPM(new Date(assignment.end.hour))}</p>
                        </div>
                     }
                        
                        
                     </div>
                     }
                  
                     {
                        assignment === null?
                        null
                        :
                        <div class=''>
                           Hosted by {assignment.user.firstname} {' '} {assignment.user.lastname}
                        </div>
                     }
              </div>
            </div>
         </div>
         {
         show.page === 1?
         <div>
            <div className='assignment-summary'>
                  <div class="columns">
                     <div class="column is-half">
                        <div className='assignment-summary-left'>
                           <div class="columns">
                              <div class="column is-one-quarter">
                              <div className='assignment-summary-sub'>
                                 <span>0%</span>
                                 <p> correct</p>
                              </div>
                              </div>
                              <div class="column">
                                 <div className='assignment-summary-head'>
                                    <h3>
                                    Practice makes perfect!
                                    </h3>
                                 
                                 </div>
                                 <div className='assignment-summary-body'>
                                    <h5>
                                       Student again and let the same group improve their score or see if new players can beat this result.
                                    </h5> 
                                 </div>
                              </div>
                        
                           </div>
                        </div>
                     </div>
                     <div class="column">
                     {
                        assignment === null?
                        null
                        :
                        <div className='assignment-summary-left'>
                           <div className='assignment-summary-details'>
                           <h2>Assignment details</h2>
                           </div>   
                           {
                              assignment === null  ||   assignment === undefined ? 
                              null
                              :
                                 <div className='assignment-summary-details'>
                                 {
                                    assignment.event === null  ||   assignment.event === undefined ? 
                                    null
                                    :
                                    <h3> 
                                    <i class="fas fa-link" style={{color:'#864cbf',fontSize:'20px',marginRight:'3%'}}></i> 
                                    {assignment.event.title}</h3>
                                 }
                                 
                                 </div>
                           }
                        
                           <div className='assignment-summary-details'>
                           
                           <h5>{   assignment.students.length}</h5>
                              <h3> <i class="fas fa-user-graduate" style={{color:'#864cbf',fontSize:'20px',marginRight:'3%'}}></i> Students</h3>
                           </div>
                           <div className='assignment-summary-details'>
                           <h5>{assignment.player.length}</h5>
                              <h3><i class="fas fa-user" style={{color:'#864cbf',fontSize:'20px',marginRight:'3%'}}></i> Players</h3>
                           </div>
                           <div className='assignment-summary-details'>
                           <h5>{assignment.question.length}</h5>
                              <h3> <i class="fas fa-question-circle" style={{color:'#1368ce',fontSize:'20px',marginRight:'3%'}} ></i> Questions </h3>
                     
                           </div>
                        </div>
                     }
                     </div>
                  <div class="column">
                     <div className='assignment-image'> 
                        <img src='https://res.cloudinary.com/codepally/image/upload/v1680262778/UFUON%20LOGO%20PNG/INQUISITIVE_KID_pdxlfl.png'
                        width={'100%'} style={{height:130}} />
                     </div>     
                  </div>
               </div>
            </div>
   
   
            <div className='assignment-difficult'>
                  <div class="columns">
                     <div class="column is-half">
                           <div className='assignment-difficult-sub'>
                                 <div className='assignment-difficult-head'>
                                 <h2>Difficult questions(0)</h2>
                              </div>
                           <p>Great job! No one found any questions too challenging.</p>  
                           </div>
                     </div>
   
                     <div class="column">
                        <div className='assignment-summary-left'>
                           <div className='assignment-summary-details'>
                           <h2>Need help (0)</h2>
                           </div>
                          
                        
                        </div>
                     </div>
   
                     <div class="column">
                        <div className='assignment-summary-left'>
                           <div className='assignment-summary-details'>
                           <h2>Didn't finish      (0)</h2>
                           </div>
                          
                         
                        </div>
                     </div>
                  </div>
              </div>
           </div>
          :
          null   
         }
       
          {
             show.page === 2 ?  
              <div className='assignment-player'>
                <h2>All Students</h2>
               {
                  assignment === null  ||     assignment === undefined?
                  null
                  :
                  <table class="table  is-bordered assignment-table">
                        <thead>
                           <tr>
                              <th><abbr title="Position">  Fullname</abbr></th>
                              <th>Rank</th>
                             
                        
                           </tr>
                        </thead>
                  
                        <tbody>
                        {
                           assignment.students.map(item => {
                               return(
                                 <tr>
                                    <th style={{width:'40%'}}><strong>{item.firstname } {' '} {item.firstname}</strong></th>
                                    <td><a href="#" title="Leicester City F.C.">{item.firstname } {' '} {item.firstname}</a> <strong>(C)</strong>
                                    </td>
                                  
                                 </tr>
                              )})
                           }
                           
                        </tbody>
                  </table>
               }
                  
               <h2>All Plays</h2>
                  {
                     assignment === null  ||     assignment === undefined?
                     null
                     :
                     <table class="table  is-bordered assignment-table">
                           <thead>
                              <tr>
                                 <th><abbr title="Position">  Fullname</abbr></th>
                                 <th>Rank</th>
                              
                           
                              </tr>
                           </thead>
                     
                           <tbody>
                           {
                              assignment.player.map(item => {
                                 return(
                                    <tr>
                                       <th style={{width:'40%'}}><strong>{item.firstname } {' '} {item.firstname}</strong></th>
                                       <td><a href="#" title="Leicester City F.C.">{item.firstname } {' '} {item.firstname}</a> <strong>(C)</strong>
                                       </td>
                                       
                                    </tr>
                                 )})
                              }
                              
                           </tbody>
                     </table>
                  }
              </div>
              :
              null
          }
     
          {
             show.page === 3 ?
             <div className='assignment-questions'>
             <h2>All Questions</h2>
             
                  {
                     assignment === null  ||     assignment === undefined?
                     null
                     :

                     <div className='assignment-quiz-fullbody'>
                     {
                        assignment.question.length === 0 ?
                        
                           <div class="content has-text-grey has-text-centered assPublicAss">
                              <p><span class="icon is-large"><i class="mdi mdi-emoticon-sad mdi-48px"></i></span></p>
                              <p>No Books</p>
                           </div>
                      
                        :
                        <div>
                      {
                              assignment.question.map(item => {
                              return(
                                    <div >
                                    <div className='assignment-quiz-head'>
                                       {
                                          item.title
                                       }
                                       <a  onClick={()=>showQuizDelete(item)}>
                                       <i class="fas fa-trash-alt is-danger"></i>
                                       </a>
                                 
                                    </div>
                                       <div className='assignment-quiz-body'>
                                          <table class="table  is-bordered assignment-table">
                                                <thead>
                                                   <tr>
                                                      <th><abbr title="Position">   Question</abbr></th>
                                                      <th>Type</th>
                                                   </tr>
                                                </thead>
                                             
                                                <tbody>
                                                {
                                                   item.questions.map(itemData => {
                                                return(
                                                         <tr>
                                                            <th style={{width:'90%'}}>
                                                               {itemData.question}
                                                            </th>
                                                            <td>
                                                               {itemData.typeof}
                                                            </td>
                                                            
                                                            
                                                         </tr>
                                                   )})
                                                }
                                                
                                                   
                                                </tbody>
                                          </table>
                                       </div>
                                  </div>   
                                )})
                             }

                        </div>
                     }
                    
                    </div>
                  }
               </div>
          :
          null
          }

          {
            show.page === 4 ?
            <div className='assignment-questions'>
            {
               assignment.books.length === 0 ?
               
                  <div class="content has-text-grey has-text-centered assignment-length">
                     <p><span class="icon is-large"><i class="mdi mdi-emoticon-sad mdi-48px"></i></span></p>
                     <p>No Books</p>
                  </div>
             
               :
                     <div class="main-list">
                     {
                       assignment.books.map(item => {
                           return(
                              <a class="assignment-book" onClick={()=>showBookDelete(item)} >
                                 <div >
                                    <div class=" iaUqVE">
                                    {
                                       item.image === null ||  item.image === undefined ?
                                       <img src="https://images-cdn.kahoot.it/14ccdd09-4407-4a9c-8ff6-e7b527ca6f0b?auto=webp&amp;width=400" alt="" class="jDhsfY"/>
                                       :
                                       <img src={item.image.url} alt="" class="jDhsfY"/>
                                    }
                                   </div>
                                       <h3>  { truncateText( item.title, {word: true, limit: 8, after: true} )}</h3>
                                    
                                    </div>
                              </a>
                           )
                        })
                     }
                </div>
              }
            </div>
          :
          null
          }

         
            <div id="modal-js-example"  class={lessonModal?"modal  is-active": "modal"}>
              <div class="modal-background"></div>
                 
                  <div class="modal-content" style={{position:'relative',bottom:'10%'}}>
                  {
                     confirm.page === 1 ?
                     <div class="box">
                           <div className='assignment-input'>
                           <input class="input is-small is-success" type="text" placeholder="Search Lesson"/>
                           </div>
                           <div className='assignment-list'>
                           <h3>List of your lesson</h3>

                           {
                              lessons.map(item => {
                                 return(
                                       <a onClick={()=>showLessonData(item)} >
                                          <div class="assignment-card-modal">
                                                <h5>{item.title}</h5>
                                          </div>
                                       </a>
                                    
                                 )
                              })
                              }
                           
                           </div>
                     </div>
                    : confirm.page === 2?
                          <div class="box">
                              <div className="assigment-modal-title">
                                    <h2>Are you sure you want to add this lesson ({lessonData.title}) link to </h2>
                                     <p> this assigment ({assignment.name})</p>
                              <div class="field is-horizontal">
                                    <div class="field-body">
                                    
                                       {
                                          isLoaded ?
                                          <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                                <button class="button is-white   is-loading" >Loading</button>
                                          </div>
                                          :
                                          <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                          <button class="button btn-default  is-danger is-small" onClick={(e)=>setConfirm({page:1,title:'home'})}>
                                          Back
                                          </button>
                                                <button class="button is-primary is-outlined is-small"  onClick={(e)=>clickEventListener()}>Submit</button>
                                               
                                          </div> 
                                       } 
                                       </div>
                                 </div> 
                             </div>
                          </div>
                     :
                     null
                  }
                     
                  </div>

                  <button class="modal-close is-large" aria-label="close"  onClick={()=>setLessonModal(false)}></button>
              </div>

              <div id="modal-js-example"  class={bookModal?"modal  is-active": "modal"}>
                <div class="modal-background"></div>
                  {
                  uploadShow.page === 1 ?
                     <div class="modal-content" style={{position:'relative',bottom:'10%'}}>
                     {
                       confirmBook.page === 1 ?
                             <div class="box box-modal-custom">
                               <a class="button is-small is-success" style={{ float:"right",}} onClick={()=> addFileUpload()}>
                                   <span class="icon is-small">
                                   <i class="fas fa-plus"></i>
                                   </span>
                                </a>
                                <div className='assignment-input'>
                                
                                   <input class="input is-small is-success" type="text" placeholder="Search Lesson"/>
                                 
                                </div>
                                <h3>List of your Books</h3>
                                <div className='assignment-list'>
                                   <div class="main-list">
                                         {
                                            books.map(item => {
                                               return(
                                                  <a class="assignment-book-modal" onClick={()=>showBookData(item)} >
                                                     <div >
                                                        <div class="styles__ImageSection-sc-exw3ub-2 iaUqVE">
                                                        {
                                                           item.image === null ||  item.image === undefined ?
                                                           <img src="https://images-cdn.kahoot.it/14ccdd09-4407-4a9c-8ff6-e7b527ca6f0b?auto=webp&amp;width=400" alt="" class="jDhsfY"/>
                                                           :
                                                           <img src={item.image.url} alt="" class="jDhsfY"/>
                                                        }
                                                        
                                                           
                                                           </div>
                                                           <h4>  { truncateText( item.title, {word: true, limit: 7, after: true} )}</h4>
                                                        
                                                        </div>
                                                  </a>
                                               )
                                            })
                                         }
                                         
                                   
  
                                   </div>
                                </div>
                             </div>
                             :
                             <div class="box">
                            {
                               bookData === null?
                               null
                              :
                              <div className="assigment-modal-title">
                                            <h2>Are you sure you want to add this book ({bookData.title}) link to </h2>
                                            <p> this assigment ({assignment.name})</p>
                                      <div class="field is-horizontal">
                                            <div class="field-body">
                                            
                                               {
                                                  isLoaded ?
                                                  <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                                        <button class="button is-white   is-loading" >Loading</button>
                                                  </div>
                                                  :
                                                  <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                                  <button class="button btn-default  is-danger is-small" onClick={(e)=>BackToAssignment()}>
                                                  Back
                                                  </button>
                                                        <button class="button is-primary is-outlined is-small"  onClick={(e)=>clickBookListener()}>Submit</button>
                                                     
                                                  </div> 
                                               } 
                                               </div>
                                         </div> 
                                   </div>
                            }
                                      
                         
                         </div>
                          }
                    </div>
                    :uploadShow.page === 2 ?
                        <div class="modal-content" style={{position:'relative',bottom:'10%'}}>
                        {
                         step.page === 1?
                         <div class="box">
                                 <a class="button is-small is-danger" style={{ float:"right",}} onClick={()=> setUploadShow({page:1,title:'home'})}>
                                    <span class="icon is-small">
                                    <i class="fas fa-times-circle"></i>
                                    </span>
                                 </a>
                                 <h3>Upload Book</h3>
                                       <div class="field">
                                          <label class="label">Title</label>
                                          <div class="control">
                                             <input class="input" type="text" placeholder="Title"
                                             onChange={(val) => textTitle(val.target.value)}
                                             />
                                          </div>
                                          {
                                             titleBookError?
                                             <p style={{textAlign:'center', color:'red', fontSize:'17px' ,marginBottom:'5px', marginTop:1}}>    {message}</p> 
                                             :
                                             null
                                         }
                                       </div>

                                       <div class="field">
                                       <label class="label">Name</label>
                                       <div class="control">
                                          <input class="input" type="text" placeholder="Authors"
                                          onChange={(val) => textAuthors(val.target.value)}
                                          />
                                       </div>
                                       
                                       {
                                          authorsBookError?
                                          <p style={{textAlign:'center', color:'red', fontSize:'17px' ,marginBottom:'5px', marginTop:1}}>    {message}</p> 
                                          :
                                          null
                                      }
                                    </div>

                                    <div class="field">
                                       <label class="label">Language</label>
                                       <div class="control">
                                          <input class="input" type="text" placeholder="Language"
                                          onChange={(val) => textLanguage(val.target.value)}
                                          />
                                       </div>
                                       {
                                          languageBookError?
                                          <p style={{textAlign:'center', color:'red', fontSize:'17px' ,marginBottom:'5px', marginTop:1}}>    {message}</p> 
                                          :
                                          null
                                        }
                                  
                                    </div>

                                    <div class="field">
                                       <label class="label"> Description</label>
                                       <div class="control">
                                          <input class="input" type="text" placeholder="Description"
                                          onChange={(val) => textDescription(val.target.value)}
                                          />
                                       </div>
                                       
                                       {
                                          descBookError?
                                          <p style={{textAlign:'center', color:'red', fontSize:'17px' ,marginBottom:'5px', marginTop:1}}>    {message}</p> 
                                          :
                                          null
                                        }
                                  
                                    </div>

                                    <div class="field is-grouped">
                                       <div class="control">
                                          <button class="button is-link" onClick={()=>nexStep()}>Submit</button>
                                       </div>
                                       <div class="control">
                                          <button class="button is-link is-light">Back</button>
                                       </div>
                                   </div>
                              </div>
                              :
                              <div class="box">
                              <a class="button is-small is-danger" style={{ float:"right",}} onClick={()=> setUploadShow({page:1,title:'home'})}>
                                    <span class="icon is-small">
                                    <i class="fas fa-times-circle"></i>
                                    </span>
                                 </a>
                                 
                                 <div className='assignment-list'>
                                 {
                                    files.length === 0 ? 
                                       <h3 style={{position:'relative',bottom:'5px'}}>Upload Book</h3>
                                       :
                                       null
                                 }
                                       <section className="container">
                                       
                                          {
                                             files.length === 0 ? 
                                                <div {...getRootProps({className: 'dropzone disabled'})}>
                                                   <input {...getInputProps()}  multiple={true}/>
                                                   <p>Drag 'n' drop some files here, or click to select files</p>
                                                </div>
                                             :
                                                null
                                          }
                                          {
                                             files.length === 0 ? 
                                             null
                                             :
                                             <h3 style={{position:'relative',right:'3%'}}>Files</h3>
                                          }
                                    
                                          <div>
                                       
                                             <ul>{files}</ul>
                                          </div>
                                    </section>
                                    {
                                      files.length === 0?
                                      null
                                      :
                                      <div style={{marginTop:'5%',textAlign:'center'}}>
                                       {
                                          loading?
                                          <button class="button is-rounded is-loading">Rounded</button>
                                          :
                                          <button class="button is-success" onClick={()=>submitBookData()}>Upload or submit</button>
                                       }
                                          
                                       </div>
                                    }
                                    
                                 
                                 </div>
                              </div>
                              }
                        </div>
                     :
                     null
                  }
                  <button class="modal-close is-large" aria-label="close"  onClick={()=>setBookModal(false)}></button>
              </div>

              <div id="modal-js-example"  class={deleteBook?"modal  is-active": "modal"}>
                <div class="modal-background"></div>

                  <div class="modal-content" style={{position:'relative',bottom:'10%'}}>
                           <div class="box">
                           {
                              bookData === null?
                              null
                             :
                               <div className="assigment-modal-title">
                                          <h2>Are you sure you want to remove this book ({bookData.title}) from </h2>
                                          <p> this assigment ({assignment.name})</p>
                                    <div class="field is-horizontal">
                                          <div class="field-body">
                                          
                                             {
                                                isLoaded ?
                                                <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                                      <button class="button is-white   is-loading" >Loading</button>
                                                </div>
                                                :
                                                <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                                <button class="button btn-default  is-danger is-small" onClick={(e)=>setDeleteBook(false)}>
                                                Close
                                                </button>
                                                      <button class="button is-primary is-outlined is-small"  onClick={(e)=>RemoveBookFromLesson()}>Submit</button>
                                                   
                                                </div> 
                                             } 
                                             </div>
                                       </div> 
                                 </div>
                               }
                       
                           </div>
                  </div>

                  <button class="modal-close is-large" aria-label="close"  onClick={()=>setBookModal(false)}></button>
              </div>

              <div id="modal-js-example"  class={deleteQuizModal?"modal  is-active": "modal"}>
                  <div class="modal-background"></div>

                     <div class="modal-content" style={{position:'relative',bottom:'10%'}}>
                              <div class="box">
                              {
                                 quizsData === null?
                                 null
                                 :
                                 <div className="assigment-modal-title">
                                             <h2>Are you sure you want to remove this quiz ({quizsData.title}) from </h2>
                                             <p> this assigment ({assignment.name})</p>
                                       <div class="field is-horizontal">
                                             <div class="field-body">
                                             
                                                {
                                                   isLoaded ?
                                                   <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                                         <button class="button is-white   is-loading" >Loading</button>
                                                   </div>
                                                   :
                                                   <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                                   <button class="button btn-default  is-danger is-small" onClick={(e)=>setDeleteQuizModal(false)}>
                                                   Close
                                                   </button>
                                                         <button class="button is-primary is-outlined is-small"  onClick={(e)=>RemoveQuizFromAssignment()}>Submit</button>
                                                      
                                                   </div> 
                                                } 
                                                </div>
                                          </div> 
                                    </div>
                                 }
                           
                              </div>
                     </div>

                     <button class="modal-close is-large" aria-label="close"  onClick={()=>setDeleteQuizModal(false)}></button>
              </div>

              



              <div id="modal-js-example"  class={quizModal?"modal  is-active": "modal"}>
                <div class="modal-background"></div>
                  <div class="modal-content" style={{position:'relative',bottom:'10%'}}>
                   {
                      quizShow.page === 1?
                        <div class="box box-modal-custom">
                           <div className='assignment-input'>
                              <input class="input is-small is-success" type="text" placeholder="Search Lesson"/>
                           </div>
                           <h3>List of your Quiz</h3>
                           <div className='assignment-list'>
                              <div class="main-list">
                                    {
                                       quizs.map(item => {
                                          return(
                                             <a class="assignment-quiz-modal" onClick={()=>showQuizData(item)} >
                                                
                                                   {
                                                      item.image === null ||  item.image === undefined ?
                                                      <img src="https://images-cdn.kahoot.it/14ccdd09-4407-4a9c-8ff6-e7b527ca6f0b?auto=webp&amp;width=400" alt="" class="jDhsfY"/>
                                                      :
                                                      <img src={item.image.url} alt="" class="jDhsfY"/>
                                                   }
                                                   
                                                   <div >
                                                      <h3>  { truncateText( item.title, {word: true, limit: 7, after: true} )}</h3>
                                                   
                                                   </div>
                                             </a>
                                          )
                                       })
                                    }
                                    
                              

                              </div>
                           </div>
                        </div>
                     :
                      quizShow.page === 2 ?
                      <div class="box">
                       <div className="assigment-modal-title">
                              <h2>Are you sure you want to add this book ({quizsData.title})  to </h2>
                              <p> this assigment ({assignment.name})</p>
                        <div class="field is-horizontal">
                              <div class="field-body">
                              
                                 {
                                    isLoaded ?
                                    <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                          <button class="button is-white   is-loading" >Loading</button>
                                    </div>
                                    :
                                    <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',}}>
                                    <button class="button btn-default  is-danger is-small" onClick={(e)=>  setQuizShow({page:1,title:'show data'})()}>
                                    Back
                                    </button>
                                          <button class="button is-primary is-outlined is-small"  onClick={(e)=>AddQuizListener()}>Submit</button>
                                       
                                    </div> 
                                 } 
                                 </div>
                           </div> 
                     </div>
                      </div>
                      :
                      null


                   }
                
                  </div>

                  <button class="modal-close is-large" aria-label="close"  onClick={()=>setQuizModal(false)}></button>
              </div>

              



         </div> 
        

    )
  
}

export default Assignment;



