import React, {Component,useState,useRef,useEffect} from 'react';
import AuthService from "../../services/auth.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './code.css';

const Assignment = () => {
      const URL_EXAM_END = "https://exam-end.herokuapp.com";
      const currentUser = AuthService.getCurrentUser();
    const [show,setShow] = useState({page:1,title:'Summary'})
    const [isLoaded ,setIsLoaded]=useState(false);
    const [nameBookError, setNameBookError ] = useState(false);
    const [messageNameBook, setMessageNameBook] = useState(null);
    const [message,setMessage] = useState(''); 
    const [error,setError] = useState(false); 
    const [errorCode,setErrorCode] = useState(false); 
    const [lesson,setLesson]= useState(null); 
    
    const [value, setValue] = React.useState("");
    const [quizShow,setQuizShow] = useState({page:1,title:'show data'})
    const [modal,setModal]= useState(true)
    const [typyOf, setTypeOf]= useState(false)
       
  useEffect(()=> {

    // props.error404(false)
    // props.updateTitle('course')
    // props.error404(false)
    // console.log("data ------article----->",params.id)
    // setUserId(params.id)
    const fetchData = async () => {
        
 
          
          
     }  
    fetchData();
 },[])


 const textFirstnameChange =  (val) => {
  // setNameBook
  if( val.length !== 0 ) {
    setValue(val)
      const reg = /^(\d*)(\.(\d*))?$|^$/
      if (reg.test(val) === true){
       
          setNameBookError(true)
          setError(true)
          setMessage('Input not  valid')
      }
      else{
         
           setNameBookError(false)
           setMessageNameBook(null)
           setError(false)
        setMessage('Input not  valid')
      }

  } else {
    setValue(val)
  }
}


 const SubmitCodeData = () =>{
  setIsLoaded(true)
    // console.log("data ----true-judge-article----->",user)
    if(value === '' || nameBookError === true ||value === null || value.length === 0 ){
 
        setNameBookError(true)
        setMessageNameBook('Name not  valid')
        setError(true)
        setMessage('Input not  valid')
        setIsLoaded(false)
        return
    }
      const data = {
      code:value
    }
    const requestOptions = {
        method: 'POST',
          headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
          //   'x-access-token': Auth.getToken()
        },
        body: JSON.stringify(data)
        };
        fetch(`${URL_EXAM_END}/assignment/code-web/`+value, requestOptions)
            .then(res => res.json())
            .then(res => {
            
              if(res.status  ===true){
                setIsLoaded(false)
                setLesson(res.data)
                // toast("Save successfully ")
                setShow({page:2,title:'Summary'})
              }else{
                setIsLoaded(false)
                setErrorCode(true)
                toast("We didn't recognize that code PIN. Please check and try again.")
                timeout()
              }
            
        
        }) .catch((error) => {
          console.error('Error:', error);
          setIsLoaded(false)
          toast("We didn't recognize that code PIN. Please check and try again.")
      });

 }
 const closeData = ()=>{
  setShow({page:1,title:'Summary'})
 }


 const timeout=() =>{
  setTimeout(function() {
    setErrorCode(false)
  //   console.log(this.state.noticeshow);
  }.bind(this), 4000);
}

const isValidURL =(string) => {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if (!regex.test(string)) {
    // window.location.replace(`http://${string}`);
    window.open(`http://${string}`, '_blank');
    // return false;
  } else {
    window.open(`${string}`, '_blank');
    // window.location.replace(`${string}`);
   
    // return true;
  }
}


const  handleType= (e) => {
  
  if(e.target.value === true){
      setTypeOf(false)
  
      setQuizShow({page:2,title:'show data'})
     }else{
      
      setTypeOf(true)
      setQuizShow({page:2,title:'show data'})
  }

//   setType(e.target.value) 
//  props.getAge(e.target.value)
}





    return(
            <div className="code-full">
              <ToastContainer />
                    <div class="columns">
                        <div class="column">
                          <div className='coKalA'>
                          </div>
                        </div>
                        <div class="column is-half">
                            <div class="code-image">
                              <img alt='sce' src="https://res.cloudinary.com/codepally/image/upload/v1676975708/UFUON%20LOGO%20PNG/Ufuon_ordinary_qxmvqp.png"  style={{height:80,width:'100%'}}/>
                            </div>
                            <div className='code-body'>
                                <div class="field">
                                        <div class={isLoaded ?"control is-large is-loading": "control is-large "}>
                                            <input class="input is-large" type="text"
                                            onChange={(val) => textFirstnameChange(val.target.value)}
                                            placeholder="Enter code"/>
                                        </div>
                                    </div>
                                    {
                                      error?
                                      <p style={{textAlign:'center', color:'red', fontSize:'17px' ,marginBottom:'5px', marginTop:1}}>    {message}</p> 
                                      :
                                      null
                                  }
                                    {
                                        show.page === 1?
                                        <div>
                                        {
                                          isLoaded ? 
                                          null
                                          :
                                           <button className="code-buttom" onClick={()=>SubmitCodeData()}>Submit</button>
                                        }
                                        
                                      </div>
                                      :
                                      null
                                    }
                               
                           </div>  
                           {
                      
                            show.page === 2?
                            <div className='code-search-list'>
                                  <i class="fas fa-times-circle" onClick={()=>closeData()}></i>
                                 
                                  {
                                    lesson === null ||  lesson === undefined?
                                      null
                                    :
                                    <div>
                                        {
                                          lesson.event === null  || lesson.event === undefined?
                                            null
                                              :
                                              <a  target="_blank"  onClick={()=>isValidURL(lesson.event.link)}>
                                                <div className='code-addLink'>
                                              
                                                  <h3> Lesson</h3> 
                                          
                                                {
                                                  lesson.event === null  || lesson.event === undefined?
                                                  null
                                                  :
                                                  <h2> Click To Open Lesson Link</h2> 
                                                }
                                                  
                                                </div>
                                              </a>
                                         }
                                    </div>
                                   
                                  }
                                  <a href={`/assignment-detail/${lesson._id}`}>
                                      <div className='code-assignment'>
                                        <h2> Assignment </h2> 
                                      </div>
                                      <div className='code-assignment-hd'>
                                        <h2> Assignment details</h2> 
                                      </div>
                                  </a>
                            </div>
                           :
                            null
                           }
                             
                        </div>
                       <div class="column">
                           <div className='fhANkk'>
                       </div>
                     </div>
                   </div>
                {
                  errorCode?
                  <div class="code-error">
                  <h2><i class="fas fa-exclamation-circle"></i> We didn't recognize that code PIN. Please check and try again.</h2>
                </div>
                :
                null
                }


              
               
           </div>
    )




}

export default Assignment;
