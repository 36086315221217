import React, { useState, useRef,useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from "formik";
import OTP from "../Login/otpInput";
import DatePicker from "react-datepicker";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput ,{ formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
// import "./App.css";
import { getMonth } from "../../utils/util";
import CalendarHeader from "../calendar/CalendarHeader";
import AuthService from "../../services/auth.service";
import Sidebar from "../calendar/Sidebar";
import Month from "../calendar/Month";
import GlobalContext from "../context/GlobalContext";
import EventModal from "../calendar/EventModal";
import "react-datepicker/dist/react-datepicker.css";


import "./signup.css"
const required = (value) => {
  
    if (!value) {
      return (
        <div className="invalid-feedback d-block">
          This field is required!
        </div>
      );
    }
  };

  
function SignUp() {
  const URL_EXAM_END = "https://exam-end.herokuapp.com";
  const URL_LOCAL = "http://localhost:4000"; 

    const form = useRef();
    const checkBtn = useRef();
  
    // useEffect(()=> {
    //   const user = AuthService.getCurrentUser();
       
    //   if (user) {
    //      timePush()
    //   }
  
    // }, []);
  
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [chooseData, setChooseData] = useState("");
    const [getType, setType]= useState(null)
    const [step,setStep] = useState({page:1,title:'show data'})

    const currentDate = new Date();
    const [codeInfo,setCodeInfo] = useState({page:1,title:'show data'})
  const [myMonth, setMyMonth] = useState(currentDate);
  const [myYear, setMyYear] = useState(currentDate);
  const [myDay, setMyDay] = useState(currentDate);
  const [value, setValue] = useState()
  const [invalid,setInvalid]=useState(false);
  const minDate = new Date(myYear.getFullYear(), myMonth.getMonth(), 1);
  const maxDate = new Date(myYear.getFullYear(), myMonth.getMonth() + 1, 0);
  const [languageBookError, setLanguageBookError] = useState(null)
  const [description,setDescription] = useState(null)
  const [descBookError,setDescBookError] = useState(false)
  const [authorsBookError ,setAuthorsBookError] = useState(false)
  const [titleBookError,setTitleBookError]= useState(false)
  const [error,setError]= useState(false)
  const [userID,setUserID]=useState(null);
  const [isLoaded ,setIsLoaded]=useState(false);
  const [school, setSchool]=useState(null);
  const [schoolError,setSchoolError]=useState(null);
  const [lastname,setLastname]=useState(null);
  const [lastnameError,setLastnameError]=useState(null);
  const [firstNameError,setFirstNameError]=useState(null);
  const [firstName,setFirstName]=useState(null);
  const [age,setAge]=useState(null);
  const [birthday,setBirthday]=useState(null);
  const [gender,setGender]=useState(null);
  
  useEffect(() => {
    setMyDay(new Date(myYear.getFullYear(), myMonth.getMonth(), 1));
  }, [myMonth, myYear, setMyDay]);

  const renderDayContents = (day, date) => {
    if (date < minDate || date > maxDate) {
      return <span></span>;
    }
    return <span>{date.getDate()}</span>;
  };
  
    const navigate = useNavigate();
  
    const onChangeUsername = (e) => {
      const username = e.target.value;
      setUsername(username);
    };
  
    const onChangePassword = (e) => {
      const password = e.target.value;
      setPassword(password);
    };
  
    const handleLogin = (e) => {
      e.preventDefault();
      setStep({page:3,title:'Choose'})
    };
  

    function getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
  }
  
  
    const timeOUT =()=>{
      // console.log('login---------')
      setTimeout(function(){
        navigate("/class");
        window.location.reload();
     }, 1900);
    }
  
  
  
    const timePush =()=>{
      setTimeout(function(){
        navigate("/class");
        window.location.reload();
     }, 1000);
    }
  
  
    const NextData  = async (data ,step) =>{
      setType(data)  
      let obj = {  
          data: data,  
          step: step +1,  
          position:data
        }  
        setChooseData(data)
        setStep({page:4,title:'Choose'})
    //  console.log("data-----1-------->",data)
      //  AsyncStorage.setItem('position',JSON.stringify(obj));  
      //  setPick(JSON.parse(await AsyncStorage.getItem('position')))
    }

   const moveToPhone = async (data ,step) =>{
      console.log( 'myYear',myYear.getFullYear(), 'myMonth',myMonth.getMonth(),'myDay',myDay.getDay())
      var d = new Date(myYear.getFullYear(), myMonth.getMonth(), myDay.getDay());
        // console.log('give me day--------->',d, getAge(d))
        setBirthday(d)
        setAge(getAge(d))
        setStep({page:3,title:'Choose'})
    }

    const NextStudent = async (data ,step) =>{
      setType(data)  
      let obj = {  
          data: data,  
          step: step +1,  
          position:data
        }  
        setChooseData(data)
        setStep({page:2,title:'Choose'})
    //  console.log("data-----1-------->",data)
    }

    const textFirstName=(val) => {
      // setNameBook
      if( val.length !== 0 ) {
        setFirstName(val)
          const reg = /^(\d*)(\.(\d*))?$|^$/
          if (reg.test(val) === true){
              setFirstNameError(true)
              setError(true)
              setMessage('Input not  valid')
          }
          else{
   
            setFirstNameError(false)
               // setMessageNameBook(null)
               setError(false)
            setMessage('Input not  valid')
          }
    
      } else {
        setFirstNameError(val)
      }
    }

    const textLastname = (val) => {
 // setNameBook
     if( val.length !== 0 ) {
        setLastname(val)
          const reg = /^(\d*)(\.(\d*))?$|^$/
          if (reg.test(val) === true){
              setLastnameError(true)
              setError(true)
              setMessage('Input not  valid')
          }
          else{

              setAuthorsBookError(false)
              // setMessageNameBook(null)
              setError(false)
            setMessage('Input not  valid')
          }

      } else {
        setLastname(val)
      }
  }


  const textSchool = (val)=>{
    // setNameBook
    if( val.length !== 0 ) {
      setSchool(val)
        const reg = /^(\d*)(\.(\d*))?$|^$/
        if (reg.test(val) === true){
            setSchoolError(true)
            setError(true)
            setMessage('Input not  valid')
        }
        else{

            setAuthorsBookError(false)
            // setMessageNameBook(null)
            setError(false)
          setMessage('Input not  valid')
        }

    } else {
      setSchool(val)
    }
  }

    const textDescription =(val) => {
      // setNameBook
      if( val.length !== 0 ) {
        setDescription(val)
          const reg = /^(\d*)(\.(\d*))?$|^$/
          if (reg.test(val) === true){
              setDescBookError(true)
              setError(true)
              setMessage('Input not  valid')
          }
          else{
   
               setAuthorsBookError(false)
               // setMessageNameBook(null)
               setError(false)
            setMessage('Input not  valid')
          }
    
      } else {
         setDescription(val)
      }
    }
  
    const TeacherData = ()=>{
        // console.log('Teacher----------->',value,firstName,lastname,school)
        if(!isValidPhoneNumber(value)){
          // console.log('true----------->',value)
            setInvalid(true)
            timeOUT()
            return
        }
        const data = {
          phone:value,
          firstName,
          lastname,
          username,
          email:username,
          password,
          organization:getType,
          position:getType,
          sex:gender
        }
        // console.log('sher----------->',value,firstName,lastname,school,username,password)
        const requestOptions = {
          method: 'POST',
            headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
            //   'x-access-token': Auth.getToken()
          },
            body: JSON.stringify(data)
          };
         fetch(`${URL_EXAM_END}/api/registart`, requestOptions)
         .then(res => res.json())
         .then(res => {
          // console.log('Error:---------------=--------------->', res);
           if(res.status  ===true){
             setIsLoaded(false)
            //  console.log('Error:---------------=--------------->', res);
            //  setCodeInfo({page:2,title:'show data'})
             setUserID(res.data)
             AuthService.loginWithCode(res.data)
             AuthService.loginGetId(res.data)
             AuthService.loginGetTokon(res.token)
             timeOUT()
           }else{
                setStep({page:4,title:"email"})
                setMessage(res.mgs  )
           }
         }) .catch((error) => {
       console.error('Error:', error);
      //  setIsLoaded(false)
      //  toast("We didn't recognize that code PIN. Please check and try again.")
      });
        
    }

    const StudentData = ()=>{
              console.log('sher----------->',value,firstName,lastname,school)
              if(!isValidPhoneNumber(value)){
                console.log('true----------->',value)
                  setInvalid(true)
                  // timeOUT()
                  return
              }
              const data = {
                phone:value,
                age,
                birthday,
                firstName,
                lastname,
                school
              }
              const requestOptions = {
                method: 'POST',
                  headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
                  //   'x-access-token': Auth.getToken()
                },
                  body: JSON.stringify(data)
                };
                // setCodeInfo({page:2,title:'show data'})
              fetch(`${URL_EXAM_END}/api/start-student`, requestOptions)
              .then(res => res.json())
              .then(res => {
                console.log('Error:---------------=--------------->', res);
                if(res.status  ===true){
                  setIsLoaded(false)
                  // console.log('Error:---------------=--------------->', res);
                  setCodeInfo({page:2,title:'show data'})
                  setUserID(res.data)
                      // AuthService.loginWithCode(res.data)
                  //     setQuizShow({page:1,title:'Summary'})
                  //  setModal(false)
                }else{
                  //  setIsLoaded(false)
                  // setErrorCode(true)
                  //  toast("We didn't recognize that code PIN. Please check and try again.")
                  //  timeout()
                }
              }) .catch((error) => {
            console.error('Error:', error);
            //  setIsLoaded(false)
            //  toast("We didn't recognize that code PIN. Please check and try again.")
            });
    }


  const StudentCodeSubmit = ()=>{
    console.log('login----------->',description)
          const data = {
            code:description,
            student:userID
          }
      const requestOptions = {
        method: 'POST',
          headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
          //   'x-access-token': Auth.getToken()
        },
          body: JSON.stringify(data)
        };
        fetch(`${URL_EXAM_END}/api/student/code`, requestOptions)
        .then(res => res.json())
        .then(res => {
        
          if(res.status  ===true){
            setIsLoaded(false)
            // console.log('status:---------------=--------------->', res);
            // timeOUT()
              if(getType === "Teacher"){
                // setStep({page:4,title:'Choose'})
                // console.log('status:---------------=--------Teacher------->', res)
                      }else if(getType === 'Professional'){
                        // setStep({page:4,title:'Choose'})
                        // console.log('status:---------------=--------Professional------->', res);
                      }else if(getType === 'Student'){
                        AuthService.loginWithCode(res.data)
                        AuthService.loginGetId(res.data)
                        AuthService.loginGetTokon(res.token)
                        timeOUT()
                        // console.log('status:---------------=--------Student------->', res);
                    }
              
                }else{
        
              }
           }) .catch((error) => {
      console.error('Error:', error);
    });
  }

  const  handleTerm = (e) => {
    // console.log("----=--------->",e.target.value )     
    setGender(e.target.value) 
 }

 const formik = useFormik({
  
});

    return (

           <div>
           <ToastContainer />
             <div className="">
                
                {
                  step.page === 1 ?
                  <div  className="loginChooseFullBody">
                     <h2> <b>Choose your account type</b></h2>
                        <div className="loginChooseBody">
                          <a onClick={() => {NextData("Professional",4)}}>
                              <div className="loginChoose loginChooseHover">
                                  <div className="loginChoose-subProfessional "></div>
                                    <div className="loginChoose-font Professional">
                                      <i class="fas fa-user-tie"></i>
                                    </div>
                                    <div className="loginChoose-title Professional">
                                        <p><b>Professional</b> </p>
                                    </div>
                                </div>
                              </a>
                              <a onClick={() => {NextData("Teacher",4)}}>
                                <div className="loginChoose loginChooseHoverTeacher">
                                  <div className="loginChoose-subTeacher "></div>
                                      <div className="loginChoose-font Teacher">
                                          <i class="fas fa-book-open"></i>
                                      </div>   
                                      <div className="loginChoose-title Teacher">
                                          <p><b>Teacher </b> </p>
                                      </div>
                                  </div>
                                </a>
                                <a onClick={() => {NextStudent("Student",1)}}>
                                <div className="loginChoose loginChooseHoverStudent">
                                    <div className="loginChoose-subStudent "></div>
                                      <div className="loginChoose-font Student">
                                        <i class="far fa-user"></i>
                                      </div>   

                                    <div className="loginChoose-title Student">
                                        <p><b>Student</b> </p>
                                    </div>
                                </div>
                              </a>
                        </div>
                    </div>
                  : step.page === 2 ?
                    <div className="loginChooseFullBody">
                      <h2><b>Enter your date of birth</b> </h2>
                          <div className="loginBirthBody">
                            <div className="loginBirthBodyFome">
                                  <div className="form-group signUpDate">
                                        <label htmlFor="password">Year</label>
                                    <DatePicker
                                      selected={myYear}
                                      onChange={(date) => setMyYear(date)}
                                      className="signUpDateSub"
                                      showYearPicker
                                      dateFormat="yyyy"
                                    />
                                </div>
                                <div className="form-group signUpDate">
                                <label htmlFor="password">Month</label>
                                    <DatePicker
                                      showMonthYearPicker
                                      dateFormat="MMMM"
                                      className="signUpDateSub"
                                      renderCustomHeader={({ date }) => <div></div>}
                                      selected={myMonth}
                                      onChange={(date) => setMyMonth(date)}
                                    />
                                </div>
                                <div className="form-group signUpDate">
                                <label htmlFor="password">Day</label>
                                <DatePicker
                                  dateFormat="dd"
                                  renderCustomHeader={({ date }) => <div></div>}
                                  selected={myDay}
                                  className="signUpDateSub"
                                  renderDayContents={renderDayContents}
                                  onChange={(date) => setMyDay(date)}
                                />
                          </div>
                          
                          </div>
                          <div className="form-group" style={{width:'40%',margin:'auto'}}>
                          <button onClick={()=>moveToPhone()} className="btn btn-primary btn-block" disabled={loading}>
                            {loading && (
                              <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Login</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  : step.page === 3 ?
                    <div className="loginChooseFullBody">
                       <h2><b>Create an account</b> </h2>
                       <div className="signupPhone">
                         {
                              codeInfo.page === 1?
                              <div className="event-form">
                              <label class="label"> Firstname </label>
                              <div class="control">
                                <input class="input" type="text" placeholder="Firstname"
                                onChange={(val) => textFirstName(val.target.value)}
                                />
                              </div>
                              <label class="label"> Lastname </label>
                              <div class="control">
                                <input class="input" type="text" placeholder="Lastname"
                                onChange={(val) => textLastname(val.target.value)}
                                />
                              </div>
                              <label class="label">  School name </label>
                              <div class="control">
                                <input class="input" type="text" placeholder="School name"
                                onChange={(val) => textSchool(val.target.value)}
                                />
                              </div>
                                <label class="label"> Phone </label>
                                <div class="control">
                                <PhoneInput
                                      placeholder="Enter phone number"
                                      error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                      defaultCountry="NG"
                                      value={value}
                                      onChange={setValue}/>
                                  
                                </div>

                                {
                                  invalid === true ?
                                  <div className="eventModal-p">
                                      <p> Number invalid</p> 
                                    </div>
                                    :
                                  null
                                }
                                <br/>
                                {
                                  getType === 'Professional' || getType === 'Teacher'   ?
                                  <div  style={{textAlign:'center',position:'relative',bottom:'7%'}} onClick={()=>TeacherData()}>
                                    <button class="nav-class-buttom mobile903" > 
                                    Submit
                                   </button>
                                </div>
                                : getType === "Student" ?
                                    <div  style={{textAlign:'center',position:'relative',bottom:'7%'}} onClick={()=>StudentData()}>
                                        <button class="nav-class-buttom mobile903" > 
                                        Submit
                                      </button>
                                    </div>
                                  :
                                   null
                                }
                                  
                              </div>  
                              : codeInfo.page === 2?
                              <div className="event-form">
                              <div className="event-form">
                        
                                <form  onSubmit={formik.handleSubmit}>
                                        <h2 style={{textAlign: "center"}}><b>Confirm phone number</b></h2>
                                      <label style={{textAlign: "center"}} class="label"> Enter the OTP we sent to your phone
                                      </label>
                                      <br/>
                                      <p style={{textAlign: "center"}}>
                                        <OTP  userID={userID}/>
                                      </p>
        
                                      <br/>
                                  
                                      <a> Resend OTP  </a> 
                                        <br/>
                                      
                                      <br/>
                                      <p> Need have an account? Sign up</p>
                                  </form>
                                </div>
                                <br/>
                        
                              </div>  
                              :null
                            }
                       
                       </div>
                   </div>    
                  : step.page === 4?
                  <div className="login-div">
                    <div className="login-logo">
                          <p>
                              <img alt='sce' src="https://res.cloudinary.com/codepally/image/upload/v1676975708/UFUON%20LOGO%20PNG/Ufuon_ordinary_qxmvqp.png"  style={{height:60,width:'40%'}}/>
                          </p>
                             
                             </div>
                               <div className="card ">
                                
                               <Form onSubmit={handleLogin} ref={form}>

                              
                                 <div className="form-group">
                                   <label htmlFor="username">Email </label>
                                   <Input
                                     type="text"
                                     className="form-control"
                                     name="username"
                                     value={username}
                                     onChange={onChangeUsername}
                                     validations={[required]}
                                   />
                                 </div>

                                 <div className="form-group">
                                 <label htmlFor="username">Gender</label>
                                 <select onChange={handleTerm}  value={gender} >
                                     <option>Select Gender</option>
                                             <option value={"Male"}>Male</option>
                                             <option value={"Female"}>Female</option>
                                     </select>
                                 </div>
                       
                                 <div className="form-group">
                                   <label htmlFor="password">Password</label>
                                   <Input
                                     type="password"
                                     className="form-control"
                                     name="password"
                                     value={password}
                                     onChange={onChangePassword}
                                     validations={[required]}
                                   />
                                 </div>
                       
                                 <div className="form-group">
                                   <button className="btn btn-primary btn-block" disabled={loading}>
                                     {loading && (
                                       <span className="spinner-border spinner-border-sm"></span>
                                     )}
                                     <span>Continue</span>
                                   </button>
                                 </div>
                       
                                 {message && (
                                   <div className="form-group">
                                     <div className="alert alert-danger" role="alert">
                                       {message}  <a href="/auth/login">  Login  </a>
                                     </div>
                                   </div>
                                 )}
                                 <CheckButton style={{ display: "none" }} ref={checkBtn} />
                               </Form>
                     
                             </div>
                             
                       </div>
                 :
                 null

                }
                <p style={{textAlign: 'center',marginTop:'5%',color:"#000"}}><b>
                Already have an account? <a href="/auth/login" style={{textAlign: 'center',marginTop:'5%',color:"red",textDecoration:"none"}}>  Login </a></b></p>
              </div>
           </div>
    )







}

export default SignUp;