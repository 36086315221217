import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
// import { enviroment } from "../../../src/components/enviroment";
// import { useRouter } from "next/router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "../../services/auth.service";
// import "react-intl-tel-input/dist/main.css";
import { useNavigate } from "react-router-dom";

const OTP = (props) => {
    const URL_EXAM_END = "https://exam-end.herokuapp.com";
    const URL_LOCAL = "http://localhost:4000";
    const navigate = useNavigate();
  const [phoneError, setphoneError] = useState(null);
  const [error, seterror] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, settoken] = useState(null);
  const [userData, setuserData] = useState(null);
  const [focusedInput, setFocusedInput] = useState("digit1");
  const inputRefs = useRef([]);
  const [countdown, setCountdown] = useState(60);
  const [isModalOpen, setModalOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const phoneRef = useRef(null);
  const [isLoaded ,setIsLoaded]=useState(false);

  let screenWidth = window.innerWidth;

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  

  const retriveData = () => {
    const userActive = localStorage.getItem("temp");
    console.log(userActive);
    if (!userActive) {
      settoken("no user");
      // router.push("/auth/signup")
      return null;
    } else {
      setuserData(JSON.parse(userActive));
      setPhone(JSON.parse(userActive)?.user?.phone);
    //   sendOTP(JSON.parse(userActive), JSON.parse(userActive)?.user?.phone);
    }
  };

  useEffect(() => {
    retriveData();
  }, []);



  useEffect(() => {
    // Start the countdown when the component mounts
 
    const timer = setInterval(() => {
      // Decrease countdown by 1 every second
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  const formattedCountdown = `${Math.floor(countdown / 60)
    .toString()
    .padStart(2, "0")}:${(countdown % 60).toString().padStart(2, "0")}`;

  const userDataa = JSON.parse(localStorage.getItem("temp"));

  const formik = useFormik({
      
    initialValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
    },
    validationSchema: Yup.object().shape({
      otp1: Yup.string().required(""),
      otp2: Yup.string().required(""),
      otp3: Yup.string().required(""),
      otp4: Yup.string().required(""),
      otp5: Yup.string().required(""),
    }),

    
    onSubmit: async (values) => {
      // setisLoading(true);
      setLoading(true)
      seterror(null);
      console.log(values);
      // Combine the OTP values
      const otpCode = Object.values(values).join("");
      const Body = {
        // code: otpCode,
        code:otpCode,
        student:props.userID
      };

      console.log("data----------body",Body);
      const requestOptions = {
        method: 'POST',
          headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
          //   'x-access-token': Auth.getToken()
        },
          body: JSON.stringify(Body)
        };
        fetch(`${URL_EXAM_END}/api/student/code`, requestOptions)
        .then(res => res.json())
        .then(res => {
          if(res.status  ===true){
                    //  console.log('status:---------------=--------------->', res);
                        AuthService.loginWithCode(res.data)
                        AuthService.loginGetId(res.data)
                        AuthService.loginGetTokon(res.token)
                        timeOUT()
  
                }else{
                  setLoading(false)
                  toast.error("Wrong OTP")
                  // console.log('status:---------------=--------------->', res);
              }
           }) .catch((error) => {
      console.error('Error:', error);
    });
   
    },
  });

    
  
    const timeOUT =()=>{
      // console.log('login---------')
      setTimeout(function(){
        navigate("/class");
        window.location.reload();
        setLoading(false)
     }, 1900);
    }
  
  
  
    const timePush =()=>{
      setTimeout(function(){
        navigate("/class");
        window.location.reload();
     }, 1000);
    }

  const clearValues = () => {
    // e.preventDefault();/
    formik.resetForm();
    inputRefs.current[0].focus(); // Move focus to the first input field after clearing values
  };

  const handleInput = (event, index) => {
    const { value } = event.target;
    // console.log(inputRefs?.current[index]);
    // Check if the value is empty, indicating a Backspace key press
    if (value === "") {
      if (inputRefs?.current[index] !== "" || undefined) {
        // Clear the current input field if it's not empty
        formik.setFieldValue(`otp${index + 1}`, "");
        inputRefs?.current[index - 1]?.focus();
      } else if (index > 0) {
        // Move focus to the previous input field if current field is empty
        inputRefs?.current[index - 1]?.focus();
      }
      console.log(event,index)

     
      return;
    }

    // Only allow digits (0-9)
    const isNumber = /^[0-9]$/;
    if (!isNumber.test(value)) {
      event.preventDefault();
      return;
    }

    // Update the formik values with the entered digit
    formik.setFieldValue(`otp${index + 1}`, value);

    // Move focus to the next input field if available
    if (index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    // const otpCode = Object.values(values).join("");
  };

  return (
          <>
               <div className="flex items-center justify-center   space-x-2">
                        {Object.keys(formik.values).map((key, index) => (
                          <div key={index} className="relative">
                            <input
                              type="text"
                              inputMode="numeric"
                              name={key}
                              maxLength="1"
                              pattern="[0-9]"
                              ref={(input) =>
                                (inputRefs.current[index] = input)
                              }
                              value={formik.values[key]}
                              onChange={(e) => handleInput(e, index)}
                              onBlur={formik.handleBlur}
                              className={`w-10 h-10 border border-grey-300 rounded text-center text-lg focus:outline-none focus:border-grey-300  ${
                                formik.touched[key] && formik.errors[key]
                                  ? "border-red-500"
                                  : ""
                              }`}
                            />
                            {formik.touched[key] && formik.errors[key] && (
                              <div className="absolute top-full text-red-500 text-xs mt-1">
                                {formik.errors[key]}
                              </div>
                            )}
                          </div>
                        ))}
                        <button onClick={() => clearValues()}>&times;</button>
                      </div>
                      
                     <br/>
                     {
                      loading ?
                        <button class="button is-loading">Loading</button>
                      :
                      <button  onClick={formik.handleSubmit}  class="nav-class-buttom mobile903" > 
                        Submit
                      </button>
                     }
             
          

             
      
    </>
  );
};

export default OTP;