/** @format */

import React, { useState, useEffect, useReducer, useMemo } from "react";
import GlobalContext from "./GlobalContext";
import AuthService from "../../services/auth.service";
import dayjs from "dayjs";

function savedEventsReducer(state, { type, payload }) {
  switch (type) {
    case "init":
      return payload;
    case "push":
      return [...state, payload];
    case "update":
      return state.map((evt) => (evt.id === payload.id ? payload : evt));
    case "delete":
      return state.filter((evt) => evt.id !== payload.id);
    default:
      throw new Error();
  }
}


export default function ContextWrapper(props) {
  const userId = AuthService.getUserId();
  const position = AuthService.getPosition();
  const URL_EXAM_END = "https://exam-end.herokuapp.com";
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [labels, setLabels] = useState([]);
  const [events, setEvents] = useState([]);
  const [myEvents, setMyEvents] = useState([]);
  let [savedEvents, dispatchCalEvent] = useReducer(
    savedEventsReducer,
    [],
    initEvents
  );

  let [savedEventsMyClass, dispatchCalEventMyClass] = useReducer(
    savedEventsReducer,
    [],
    initEventsMyClass
  );

  // console.log("Component is rendering...");

  async function initEvents() {
    try {
      const response = await fetch(`${URL_EXAM_END}/events`);
      const data = await response.json();
      // console.log("data ------setAssignments----->", data);
        
      if (data.data && Array.isArray(data.data)) {
        // console.log("resolved events", data.data);
        setEvents(data.data);
        return data.data;
      } else {
        // console.log("No events found or data is not an array");
        return [];
      }
    } catch (error) {
      // console.error("Error fetching events:", error);
      return [];
    }
  }

  async function initEventsMyClass() {
    try {   
    // console.log('get user id------------------------>',`${URL_EXAM_END}/events/user/${userId}`,"my class --->",position)
    if(position === "Teacher"){
      console.log("yes u are a tearcher");
      const response = await fetch(`${URL_EXAM_END}/events/user/${userId}`);
      const data = await response.json();
      // console.log("data ------my claa----->", userId,data);
      if (data.data && Array.isArray(data.data)) {
        // console.log("resolved events", data.data);
        setMyEvents(data.data);
        return data.data;
      } else {
        console.log("No events found or data is not an array");
        return [];
      }
    }else{
      // console.log('get user id------student------------------>',`{URL_EXAM_END}/lesson/student`,"my class --->",position,userId)
          const dataSend ={
            user:false
        }
    //  setLoading(true)
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dataSend),
        };
       const response = await fetch(`${URL_EXAM_END}/lesson/student/${userId}`,requestOptions);
        const data = await response.json();
        // console.log("data ------my claa----->", userId,data);
        if (data.data && Array.isArray(data.data)) {
          // console.log("resolved events", data.data);
          setMyEvents(data.data);
          return data.data;
        } else {
          // console.log("No events found or data is not an array");
          return [];
        }
    }
   
    } catch (error) {
      // console.error("Error fetching events:", error);
      return [];
    }
  }

  const filteredEventsMyClass = useMemo(() => {
    //use memo is not rerendered for some reasons, so i am assigning events to saved data and also passing events as a dependency
    savedEventsMyClass = myEvents;
    // console.log("calling ----savedEvents----------", savedEventsMyClass);
    // Check if savedEvents is a promise
    if (Array.isArray(savedEventsMyClass)) {
      // console.log("saved events resolved", savedEventsMyClass);
      // Filter events based on labels once savedEvents is resolved
      return savedEventsMyClass?.filter((evt) =>
        labels
          ?.filter((lbl) => lbl.checked)
          ?.map((lbl) => lbl.label)
          ?.includes(evt.label)
      );
    } else {
      // console.log("savedEvents is a Promise, returning empty array");
      return []; // Return an empty array while the promise is pending or in case of an error
    }
  }, [myEvents, labels]);


  const filteredEvents = useMemo(() => {
    //use memo is not rerendered for some reasons, so i am assigning events to saved data and also passing events as a dependency
    savedEvents = events;
    // console.log("calling ----savedEvents----------", savedEvents);
    // Check if savedEvents is a promise
    if (Array.isArray(savedEvents)) {
      // console.log("saved events resolved", savedEvents);
      // Filter events based on labels once savedEvents is resolved
      return savedEvents?.filter((evt) =>
        labels
          ?.filter((lbl) => lbl.checked)
          ?.map((lbl) => lbl.label)
          ?.includes(evt.label)
      );
    } else {
      // console.log("savedEvents is a Promise, returning empty array");
      return []; // Return an empty array while the promise is pending or in case of an error
    }
  }, [events, labels]);

  useEffect(() => {
    const fetchData = async () => {
      fetch(`${URL_EXAM_END}/events`)
        .then((res) => res.json())
        .then(
          (res) => {
            // console.log("data ------setAssignments----->",res)
            if (res.status === false) {
              // setIsLoaded(false);
            }
          },
          (error) => {}
        );
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (savedEvents instanceof Promise) {
      // Don't update local storage until promise resolves
      return;
    }
    localStorage.setItem("savedEvents", JSON.stringify(savedEvents));
  }, [savedEvents]);

  useEffect(() => {
    if (savedEvents instanceof Promise) {
      // Don't update labels until promise resolves
      return;
    }
    setLabels((prevLabels) => {
      return [...new Set(savedEvents?.map((evt) => evt.label))].map((label) => {
        const currentLabel = prevLabels.find((lbl) => lbl.label === label);
        return {
          label,
          checked: currentLabel ? currentLabel.checked : true,
        };
      });
    });
  }, [savedEvents]);

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setMonthIndex(smallCalendarMonth);
    }
  }, [smallCalendarMonth]);

  useEffect(() => {
    if (!showEventModal) {
      setSelectedEvent(null);
    }
  }, [showEventModal]);

  function updateLabel(label) {
    setLabels(labels.map((lbl) => (lbl.label === label.label ? label : lbl)));
  }

  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        showEventModal,
        setShowEventModal,
        dispatchCalEvent,
        selectedEvent,
        setSelectedEvent,
        savedEvents,
        dispatchCalEventMyClass,
        setLabels,
        labels,
        updateLabel,
        filteredEvents,
        filteredEventsMyClass
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}
