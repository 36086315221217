import axios from "axios";
import decode from 'jwt-decode';
const API_URL = "https://exam-end.herokuapp.com";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "/api/login", {
      username,
      password,
    })
    .then((response) => {
      // console.log("users----------user------data-->",response.data)
      if (response.data.status === "true") {
        // callUserData(response.data)
        // console.log("users----------user------data-->",response.data)
        axios.get(API_URL + `/users/${response.data.data.id}`, {
        })
        .then((response) => {
          // console.log("users----------user------data-->",response.data)
          if (response.data.status === true) {
          
            localStorage.setItem("user", JSON.stringify(response.data.data));
            // setUserID(res.data)
            AuthService.loginWithCode(response.data.data)
            AuthService.loginGetId(response.data.data)
            AuthService.loginGetTokon(response.data.data.token)
            return response.data.data
          }
        });
      }

    });
};

const loginWithCode = (data) => {
  // console.log("users----------user------data-->",data)
  localStorage.setItem("user", JSON.stringify(data));
  if(data.student === false){
        // console.log("users----------data.student------false-->",data.student)
      localStorage.setItem("position", JSON.stringify("Teacher"))
      }else{
        // console.log("users----------data.student------true-->",data.student)
        localStorage.setItem("position", JSON.stringify("Student"))
  }
;
  return 
}

const loginGetTokon = (data) => {
  // console.log("users----------loginGetTokon------data-->",data)
  localStorage.setItem("tokon", JSON.stringify(data));

}


const loginGetId = (data) => {
  // console.log("users----------loginGetId------data-->",data._id  ,data.payment)
  localStorage.setItem("id", JSON.stringify(data._id));
  localStorage.setItem("payment", JSON.stringify(data.payment));

}

const callUserData = (data)=>{
  // console.log("login-----data--------->",data)
  return axios
  .get(API_URL + `/users/${data.data.id}`, {
  })
  .then((response) => {
    // console.log("users----------user---->",response.data)
    if (response.data.status === true) {
      localStorage.setItem("user", JSON.stringify(response.data.data));
    }

    return response.data;
  });
}


const logout = () => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "signout").then((response) => {
    return response.data;
  });
};


 const loggedIn =()=> {
  // Checks if there is a saved token and it's still valid
  const token = getToken() // GEtting token from localstorage
  return !!token && !isTokenExpired(token) // handwaiving here
}

const isTokenExpired =(token)=> {
  try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
          return true;
      }
      else
          return false;
  }
  catch (err) {
      return false;
  }
}



 const setToken=(idToken)=> {
  // Saves user token to localStorage
  localStorage.setItem('tokon', idToken)
}

const getToken=()=> {
  // Retrieves the user token from localStorage
  // return localStorage.getItem('id_token')
  return JSON.parse(localStorage.getItem("user"));
}

const getUserTokon= () => {
  return JSON.parse(localStorage.getItem("tokon"));

}

const getUserId= () => {
  return JSON.parse(localStorage.getItem("id"))
}

const getPosition = () => {
  return JSON.parse(localStorage.getItem("position"))
}
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  loginGetTokon,
  loginGetId,
  loginWithCode,
  getUserId,
  getPosition
}

export default AuthService;
