
import React, {Component,useState,useRef,useEffect} from 'react';
import AuthService from "../../services/auth.service";
// import {API_BASE_URL,URL_EXAM_END, API_BASE_LOCAL,ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import Slider from "react-slick";
import './market.css';
import { useSearchParams } from 'react-router-dom'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Carousel from 'carousel-react-rcdev'
// import Carousel from 'react-elastic-carousel';

// "https://cdn.skypack.dev/react-dom";
var ID
const items = [
    {
        icon:"face",
        copy:'01. Lorem ipsum d'
    },{
        icon:"pets",
        copy:'02. Sed do ei'
    },{
        icon:"stars",
        copy:'03. Consectetur a.'
    },{
        icon:"invert_colors",
        copy:'04. Ut enim.'
    },{
        icon:"psychology",
        copy:'05. Llamco nisi.'
    },{
        icon:"brig53tness_7",
        copy:'06. Misi ut aliquip .'
    }
    ,{
      icon:"brightn533ess_7",
          copy:'07. Misi ut aliquip .'
      }
      ,{
        icon:"bright3534ess_7",
        copy:'08. Misi ut aliquip .'
    }
    ,{
      icon:"brigh3553ness_7",
      copy:'09. Misi ut aliquip .'
    }
    ,{
      icon:"bri3353htness_7",
      copy:'10. Misi ut aliquip .'
    }
];

const get_randomOut= (list)=> {
  // console.log('data------------------>',list)
  return list[Math.floor((Math.random()*list.length))];
}

const Card = (props) => {
  return (
    <li className={`card ${get_randomOut(["show-random2","show-random1","show-random3","show-random4","show-random5",
    "show-random6","show-random6","show-random7","show-random8","show-random9","show-random10",
  ,"show-random11","show-random12","show-random14","show-random15","show-random16",])}`}>
    <div  >
  
      <p>{props.copy}</p>
      </div>
    </li>
  )
}

const currentUser = AuthService.getCurrentUser();
const MarketPalace = () => {
  const URL_EXAM_END = "https://exam-end.herokuapp.com";
  const [show,setShow] = useState({page:2,title:'Summary'}) 
  const [moveClass, setMoveClass] = useState('');
  const [carouselItems, setCarouselItems] = useState(items);
  const [searchParams, setSearchParams] = useSearchParams()
  const [bookModal, setBookModal]= useState(false);
  const [isLoaded ,setIsLoaded]=useState(true);
  const [categories,seCategories] = useState([])
  const [loading , setLoading] = useState(false)
  // const [categories,setCategories] = useState([])

  const [book,setBook]= useState(null)
  
  
  useEffect(() => {
    // console.log('----search-------------->',searchParams.get('search'))
   
    document.documentElement.style.setProperty('--num', categories.length);
  }, [categories])


  useEffect(()=> {

    // props.error404(false)
    // props.updateTitle('course')
    // props.error404(false)
    // console.log("data ------article----->",params.id)
    // setUserId(params.id)
    const fetchData = async () => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify(login),
      };
      fetch(`${URL_EXAM_END}/courses`,requestOptions)
      .then(res => res.json())
      .then(
        (res) => {
  
            seCategories(res.data)
             },
          (error) => {
        }
     )
      if(searchParams.get('search') === undefined || searchParams.get('search') === null){
  
      }else{
        const reg = /^(\d*)(\.(\d*))?$|^$/
        if (reg.test(searchParams.get('search')) === true){
         
        }else{
          ID = searchParams.get('search')
          setBookModal(true)
          setIsLoaded(true)
          callParamsData()
 
        }
      }

     }  
    fetchData();
 },[])


  
  const handleAnimationEnd = () => {
    if(moveClass === 'prev'){
      shiftNext([...carouselItems]);
    }else if(moveClass === 'next'){
      shiftPrev([...carouselItems]);
    }
    setMoveClass('')
  }
  
  const shiftPrev = (copy) => {
    let lastcard = copy.pop();
    copy.splice(0, 0, lastcard);
    setCarouselItems(copy);
  }
  
  const shiftNext = (copy) => {
    let firstcard = copy.shift();
    copy.splice(copy.length, 0, firstcard);
    setCarouselItems(copy);
  }

  const callParamsData =()=>{
  
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify(login),
      };
      fetch(`${URL_EXAM_END}/books/${ID}`,requestOptions)
      .then(res => res.json())
      .then(
        (res) => {
         
            setBook(res.data)
            
             },
          (error) => {
        }
     )
  }

  const getDayOfTheMon =(data)=>{
    var dayName =['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var day = dayName[new Date(data).getDay()];
    // console.log("first one------>",new Date())
    // console.log("second one------>",new Date(data).getDay())
      return day
  }
 
  const getMonth =(data)=>{
     var day = parseInt(data) - 1
   
    var month 
    var dayName =['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];
    //  console.log("month-------------->",dayName[day] )
    return month =  dayName[day]
 
  }
 
  const formatAMPM =(date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
     
  

  function download(url, filename) {
  
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    })
    .catch(console.error);
  }
  
  
    return(
          <div className="marketPalace-full">
            <div className='marketPalace-header'>
                <h1>Explore learning content from trusted creators</h1>
                <p>Find what you need from a library of premium ready-to-use learning resources fit for all ages.</p>
                <p>Make learning awesome, no matter the subject</p>
                <div className='marketPalace-header-sub'>
                    <div class="columns">
                        <div class="column is-one-third">
                          <div className='marketPalace-header-sub-list'>
                             <img alt='list' src="https://images-cdn.kahoot.it/29eec705-b499-43dd-ad36-f4c2c9a8a5e4"/>
                             <h3>World famous Ufuon! engagement</h3>
                          </div>
                        </div>
                        <div class="column">
                            <div className='marketPalace-header-sub-list'>
                                <img alt='list' src="https://images-cdn.kahoot.it/98aad0b1-b5c2-4685-8637-52ed8e54e9b4"/>
                                <h3>Courses and interactive lessons</h3>
                              </div>
                        </div>
                        <div class="column">
                          <div className='marketPalace-header-sub-list'>
                              <img alt='list' src="https://images-cdn.kahoot.it/a2228470-d3a8-447b-8b3e-a1d83658454c"/>
                              <h3>World famous Ufuon! engagement</h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='Type-subject'>
            <h2> Topics </h2>

                <div className="carouselwrapper module-wrapper">
                    <div className="ui">
                      <button onClick={() => setMoveClass('next')} className="prev">
                        <span className="material-icons">chevron_left</span>
                      </button>
                      <button onClick={() => setMoveClass('prev')} className="next">
                        <span className="material-icons">chevron_right</span>
                      </button>
                    </div>
                    <ul onAnimationEnd={handleAnimationEnd} className={`${moveClass} carousel`}>
                        {categories.map((t, index) => 
                          <Card key={index} icon={t._id} copy={t.name} />
                        )}
                    </ul>
              </div>
           
          </div>

          <div className='trending-body'>
              <h2>Trending</h2>
              <div>
                 <div  class="styles__Card-sc-exw3ub-1 jRpFnT">
                    <div class="styles__ImageSection-sc-exw3ub-2 iaUqVE">
                      <img src="https://images-cdn.kahoot.it/14ccdd09-4407-4a9c-8ff6-e7b527ca6f0b?auto=webp&amp;width=400" alt="" class="styles__Image-sc-exw3ub-4 jDhsfY"/>
                     </div>
                    <div className='khjBgt'>
                           <div class="clrUfu">History</div>
                           <div data-functional-selector="course-card_title" class="styles__Title-sc-exw3ub-12 bTxeJG">Cinco de Mayo</div>
                             
                           <div class="styles__Bottom-sc-exw3ub-19 gswzWc"><button class="sc-fcCDlF iCmNtB styles__BaseCTAButton-sc-exw3ub-0 styles__BuyButton-sc-exw3ub-20 jriLZC dyVKmO" aria-label="Buy this course on the marketplace" aria-disabled="false" data-functional-selector="course-card_buy">Buy&nbsp;USD&nbsp;3.00</button></div>
                        </div>
                 </div>
              </div>
          </div>



          <div id="modal-js-example"  class={bookModal?"modal  is-active": "modal"}>
              <div class="modal-background"></div>
                      <div class="modal-content marketPalce-modal" style={{position:'relative',top:'1%'}}>
                          <div class="box ">
                              <div className='ma'>
                                <div class="columns">
                                  <div class="column">
                                    {
                                      book === null ?
                                      null
                                      :
                                      <div className='marketPlace-imageBook' style=
                                          {{ 
                                            backgroundImage:`url(${book.image.url})` 
                                          }}
                                        >
                                      </div>
                                    }
                                    
                                  </div>
                              
                                  <div class="column is-three-fifths">
                                    <div className='marketPlace-titleModal'>
                                    {
                                      book === null ?
                                      null
                                      :
                                        <h2>
                                           {book.title}
                                        </h2>
                                    }
                                    </div>
                                    {
                                      book === null?
                                      null
                                      :
                                      <div className='marketPlace-profile'>
                                        <div className='marketPlace-profile-left'>
                                            <a class="profile-mage"></a>
                                        </div>
                                        {
                                          book.owner  === undefined?
                                            null
                                          :
                                          <div className='marketPlace-profile-right'>
                                              <h2>Created by {book.owner.firstname  +" " + book.owner.lastname}</h2>
                                              <h4>Updated on {getDayOfTheMon(book.createdAt)}, {getMonth(new Date(book.createdAt).getMonth())},{formatAMPM(new Date(book.createdAt))}</h4>
                                          </div>
                                        }
                                       
                                      </div>
                                    }
                                    {
                                      book === null?
                                      null
                                      :
                                      <div className='marketPlace-download'>
                                         <a class="button is-link" onClick={()=>download(`https://ucarecdn.com/${book.document.version_id}/${book.document.name}`,book.document.name)}>Download</a>
                                        
                                      </div>
                                    }
                                      <div className='marketPlace-ageDel'>
                                          <div>
                                            <h2> GRADES GROUP</h2>  
                                            <h4>   4th-5th grade </h4>
                                          </div>
                                          <div>
                                            <h2> AGE GROUP</h2>  
                                            <h4>  9 - 12 </h4>
                                          </div>
                                          <div>
                                            <h2> ASSIGNMENT</h2>  
                                            <h4>   Assign as homework </h4>
                                          </div>
                                      </div>
                                     <div className='marketPlace-desc'>
                                        <h2>Course description</h2>
                                        {
                                          book === null ?
                                          null
                                          :
                                          <p>
                                          {
                                            book.description
                                          }
                                          </p>
                                        }
                                       
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                          </div>
                     </div>

                  <button class="modal-close is-large" aria-label="close"  onClick={()=>setBookModal(false)}></button>
              </div>

        
        </div>
    )



}

export default MarketPalace;

