import React, { useState, useContext, useEffect,useRef } from "react";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-phone-number-input/style.css'
import OTP from "../Login/otpInput";
import { useNavigate } from "react-router-dom";
import PhoneInput ,{ formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
// import "./App.css";
import { getMonth } from "../../utils/util";
import Input from "react-validation/build/input";
import CalendarHeader from "../calendar/CalendarHeader";
import AuthService from "../../services/auth.service";
import Sidebar from "../calendar/Sidebar";
import Month from "../calendar/Month";
import GlobalContext from "../context/GlobalContext";
import Form from "react-validation/build/form";
import EventModal from "../calendar/EventModal";
import CheckButton from "react-validation/build/button";
import axios from "axios";

import "./style.css"
import Login from "../Login/Login";

function Event() {
  const URL_EXAM_END = "https://exam-end.herokuapp.com";
  const URL_LOCAL = "http://localhost:4000";
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal } = useContext(GlobalContext);
  const [myClass , setMycLass] = useState(false)
  const [allClass , setAllcLass] = useState(true)
  const [modal,setModal]= useState(true)
  const [language, setLanguage] = useState(null)
  const [languageBookError, setLanguageBookError] = useState(null)
 const [description,setDescription] = useState(null)
 const [descBookError,setDescBookError] = useState(false)
 const [authorsBookError ,setAuthorsBookError] = useState(false)
 const [titleBookError,setTitleBookError]= useState(false)
 const [error,setError]= useState(false)
 const [title,setTitle] = useState(null)
 const [message,setMessage]= useState(null)
 const [isLoaded ,setIsLoaded]=useState(false);
 const [userID,setUserID]=useState(null);
 const [codeInfo,setCodeInfo] = useState({page:1,title:'show data'})
 const [value, setValue] = useState()
 const [invalid,setInvalid]=useState(false);
 const [step,setStep]= useState({page:1,title:'show data'})
 const [chooseData, setChooseData] = useState("");
 const [username, setUsername] = useState("");
 const [password, setPassword] = useState("");
 const [loading, setLoading] = useState(false);
 const [addClassModal,setAddClassModal] = useState(false);
 const [nameBookError, setNameBookError ] = useState(false);
 const [messageNameBook, setMessageNameBook] = useState(null);
 const [lesson,setLesson]= useState(null); 
 const [errorCode,setErrorCode] = useState(false); 
const [userId,setUserId] = useState(null); 
 const [show,setShow] = useState({page:1,title:'Summary'})
 const [includesArray,setIncludesArray]= useState([])
const navigate = useNavigate();
  
 const form = useRef();
 const checkBtn = useRef();
  useEffect(() => {
    const userIdData = AuthService.getUserId();
    if (userIdData) {
      // console.log('-----------my class',userIdData)
      // setCurrentUser(user);
      setModal(false)
      setUserId(userIdData)
    }
    // console.log('get user id',userId)
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]); 

  function MyClassData(){
  //  console.log('-----------my class')
   setMycLass(true)
   setAllcLass(false)
  }

  function handleReset(){
    setMycLass(false)
    setAllcLass(true)
  }



 
  const LoginData = ()=>{
      // console.log('login----------->',value)
      setLoading(true)
      if(!isValidPhoneNumber(value)){
        // console.log('true----------->',value)
          setInvalid(true)
          timeOUT()
          return
      }
      const data = {
        phone:value
      }
      const requestOptions = {
        method: 'POST',
          headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
          //   'x-access-token': Auth.getToken()
        },
          body: JSON.stringify(data)
        };
       fetch(`${URL_EXAM_END}/students/verification/`, requestOptions)
       .then(res => res.json())
       .then(res => {
        // console.log('Error:---------------=--------------->', res);
         if(res.status  ===true){
            setIsLoaded(false)
            setLoading(false)
          //  console.log('Error:---------------=--------------->', res);
           setCodeInfo({page:2,title:'show data'})
           setUserID(res.data)
              // AuthService.loginWithCode(res.data)
          //     setQuizShow({page:1,title:'Summary'})
          //  setModal(false)
         }else{
          toast.error("Try another Phone or Sign up .")
          setLoading(false);
          //  setIsLoaded(false)
           // setErrorCode(true)
          //  toast("We didn't recognize that code PIN. Please check and try again.")
          //  timeout()
         }
       }) .catch((error) => {
     console.error('Error:', error);
    //  setIsLoaded(false)
    //  toast("We didn't recognize that code PIN. Please check and try again.")
    });
      
  }

  const timeOUT =()=>{
    // console.log('login---------')
    setTimeout(function(){
      navigate("/class");
      window.location.reload();
   }, 1900);
  }

  const NextData  = async (data ,step) =>{
  
      setChooseData(data)
      setStep({page:3,title:'Choose'})
    //  console.log("data-----1-------->",data)
  }

  const NextStudent = async (data ,step) =>{
    // setType(data)  
  
      setChooseData(data)
      setStep({page:2,title:'Choose'})
  //  console.log("data-----1-------->",data)
  }


  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form.current.validateAll();
    //  console.log('login----------------------->',username, password)
    if (checkBtn.current.context._errors.length === 0) {
            return axios
        .post(URL_EXAM_END + "/api/login", {
          username,
          password,
        })
        .then((response) => {
          // console.log("users----------user------data-->",response.data)
          if (response.data.status === "true") {
            // callUserData(response.data)
            // console.log("users----------user------data-->",response.data)
            axios.get(URL_EXAM_END + `/users/${response.data.data.id}`, {
            })
            .then((response) => {
              // console.log("users----------user------data-->",response.data)
              if (response.data.status === true) {
              
                localStorage.setItem("user", JSON.stringify(response.data.data));
                // setUserID(res.data)
                AuthService.loginWithCode(response.data.data)
                AuthService.loginGetId(response.data.data)
                AuthService.loginGetTokon(response.data.data.token)
                timeOUT()
                return response.data.data
              }
            });
          }else{
            setLoading(false);
            // console.log('login--------------<>', response.data.message)
            setMessage(response.data.message.message);
          }

        });
      
    } else {
      setLoading(false);
    }
  };

   
  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const required = (value) => {
  
    if (!value) {
      return (
        <div className="invalid-feedback d-block">
          This field is required!
        </div>
      );
    }
  };

  const formik = useFormik({
  
  });

  const BackStep = ()=>{
    setCodeInfo({page:1,title:"Phone"})
  }

  const BackToChoose = ()=>{
    setStep({page:1,title:"Phone"})
  }

  // const BackToPro = ()=>{
  //   setStep({page:1,title:"Phone"})
  // }


  
const SubmitAdd =(data)=>{
     
      setLoading(true)
      const dataInfo={
        owner:userId,
        userInfo:false
      }  
      
      // console.log("link data------------->",dataInfo,data)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataInfo),
    };
    console.log("requestOptions",requestOptions)
    fetch(`${URL_EXAM_END}/event/${data}`, requestOptions)
    .then(res => res.json())
    .then((res)=>{
    // console.log("res---------->", res)
        if(res.status === true){
            setLoading(false)
            window.location.reload();
            // setAdded(true)
            //  events.push(user)
            // setStudent([...student, res.data])
            // toast("Update successful ")
            // setEditload(false)
        }else {
            setLoading(false)
            // toast("Update not successful ")
            // setEditload(false)
            // setPublishLoader(false)
        }
    }).catch((error) => {
        console.error('Error:', error);
    });

 }



const AddClssButton = ()=>{
  console.log('this log')
  setAddClassModal(!addClassModal)
}

const textFirstnameChange =  (val) => {
  // setNameBook
  if( val.length !== 0 ) {
    setValue(val)
      const reg = /^(\d*)(\.(\d*))?$|^$/
      if (reg.test(val) === true){
       
          setNameBookError(true)
          setError(true)
          setMessage('Input not  valid')
      }
      else{
         
           setNameBookError(false)
           setMessageNameBook(null)
           setError(false)
        setMessage('Input not  valid')
      }

  } else {
    setValue(val)
  }
}


const SubmitCodeData = () =>{
  setIsLoaded(true)
    // console.log("data ----true-judge-article----->",user)
    if(value === '' || nameBookError === true ||value === null || value.length === 0 ){
 
        setNameBookError(true)
        setMessageNameBook('Name not  valid')
        setError(true)
        setMessage('Input not  valid')
        setIsLoaded(false)
        return
    }
      const data = {
      code:value
    }
    const requestOptions = {
        method: 'POST',
          headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
          //   'x-access-token': Auth.getToken()
        },
        body: JSON.stringify(data)
        };
        fetch(`${URL_EXAM_END}/assignment/code-web/`+value, requestOptions)
            .then(res => res.json())
            .then(res => {
            
              if(res.status  ===true){
                setIsLoaded(false)
                setLesson(res.data)
                // console.log("link data------------->",res.data.event.students)
                setIncludesArray(res.data.event.students)
                // toast("Save successfully ")
                setShow({page:2,title:'Summary'})
              }else{
                setIsLoaded(false)
                setErrorCode(true)
                toast("We didn't recognize that code PIN. Please check and try again.")
                timeout()
              }
            
        
        }) .catch((error) => {
          console.error('Error:', error);
          setIsLoaded(false)
          toast("We didn't recognize that code PIN. Please check and try again.")
      });

 }

 const timeout=() =>{
  setTimeout(function() {
    setErrorCode(false)
  //   console.log(this.state.noticeshow);
  }.bind(this), 4000);
}

const isValidURL =(string) => {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if (!regex.test(string)) {
    // window.location.replace(`http://${string}`);
    window.open(`http://${string}`, '_blank');
    // return false;
  } else {
    window.open(`${string}`, '_blank');
    // window.location.replace(`${string}`);
   
    // return true;
  }
}

const closeData = ()=>{
  setShow({page:1,title:'Summary'})
 }

 const closeModal = ()=>{
  setAddClassModal(false)
 }


  return (
    <React.Fragment>
    <ToastContainer />
      {showEventModal && <EventModal />}
      <div className="h-screen flex flex-col">
        <CalendarHeader MyClassData={MyClassData} AddClssButton={AddClssButton} handleReset={handleReset}/>
        <div className="flex flex-1" >
          <Sidebar />
          <Month month={currenMonth}   myClass={myClass} allClass={allClass}/>
        </div>
        <div id="modal-js-example"  class={modal?"modal  is-active": "modal"}>
        <div class="modal-background"></div>
          <div class="modal-content " style={{position:'relative',bottom:'10%',width:"30%"}}>
            {
              step.page === 1 ?
              <div>
                <div className="eventModal">
                  <div className="event-choose-form">
                    <h2> <b>Which account</b></h2>
                     <div className="event-choose-body">
                     <a onClick={() => {NextData("Professional",3)}}>
                        <div className="event-choose">
                          <div className="event-choose-left">
                              <i class="fas fa-user-tie"></i>
                          </div>
                          <div className="event-choose-right">
                             <p><b>Professional</b></p>
                          </div>
                        </div>
                        </a>
                        <a onClick={() => {NextStudent("Student",1)}}>
                          <div className="event-choose">
                              <div className="event-choose-left-student">
                              <i class="fas fa-user-graduate"></i>
                              </div>
                              <div className="event-choose-right">
                                <p><b>Student</b></p>
                              </div>
                        </div>
                        </a>
                     </div>
                  </div>
                </div>
              </div>
             :step.page === 2 ?
              <div>
                  <div className="eventModal">
                     <a onClick={()=>BackToChoose()} className="event-back-choose"><i class="fas fa-arrow-left event-array" ></i></a>
                  {
                    codeInfo.page === 1?
                    <b>Log in</b> 
                    :
                    null
                  }
            
                  </div>   
                    {
                      codeInfo.page === 1?
                      <div className="event-form">
                        <label class="label"> Phone </label>
                        <div class="control">
                        <PhoneInput
                              placeholder="Enter phone number"
                              error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                              defaultCountry="NG"
                              value={value}
                              onChange={setValue}/>
                          
                        </div>
                        {
                          invalid === true ?
                          <div className="eventModal-p">
                              <p> Number invalid</p> 
                            </div>
                            :
                          null
                        }
                        <br/>
                        <div  style={{textAlign:'center',position:'relative',bottom:'7%',marginBottom:'3%'}} onClick={()=>LoginData()}>
                         {
                           loading ?
                           <button class="button is-loading">Loading</button>
                           :
                           <button class="nav-class-buttom mobile903" > 
                            Submit
                          </button>
                         }
                            
                        </div>
                  
                        <div className="eventModal-p">
                            <a href="/auth/signup" >
                            Don't have an account? Sign up
                            </a>
                        </div>
                      </div>  
                      : codeInfo.page === 2?
                      <div className="event-form">
                        
                            <form  onSubmit={formik.handleSubmit}>
                                <h2 style={{textAlign: "center"}}><b>Confirm phone number</b></h2>
                              <label style={{textAlign: "center"}} class="label"> Enter the OTP we sent to your phone
                              </label>
                              <br/>
                              <p style={{textAlign: "center"}}>
                                <OTP  userID={userID}/>
                              </p>

                              <br/>
                          
                              <a> Resend OTP  </a> 
                                <br/>
                              
                              <br/>
                              <p> Need have an account? Sign up</p>
                          </form>
                        </div>  
                      :null
                    }
                </div>
                :step.page === 3?
                     <div className="event-login-body">
                     <a onClick={()=>BackToChoose()} className="event-back-choose"><i class="fas fa-arrow-left event-array-pro" ></i></a>
                      <b>Log in</b> 
                         <div className="event-login-card">
                         
                              <img  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" alt="profile-img"
                              className="profile-img-card"/>
                              
                               <Form onSubmit={handleLogin} ref={form}>
                                  
                                    <div className="form-group">
                                      <label htmlFor="username">Email </label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        name="username"
                                        value={username}
                                        onChange={onChangeUsername}
                                        validations={[required]}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="password">Password</label>
                                      <Input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        value={password}
                                        onChange={onChangePassword}
                                        validations={[required]}
                                      />
                                    </div>
                          
                                    <div className="form-group">
                                      <button className="btn btn-primary btn-block" disabled={loading}>
                                        {loading && (
                                          <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Submit</span>
                                      </button>
                                    </div>
                          
                                    {message && (
                                      <div className="form-group">
                                        <div className="alert alert-danger" role="alert">
                                          {message} 
                                        </div>
                                      </div>
                                    )}
                                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                                  </Form>
                        
                                </div>
                                <div className="login-forget">
                             
                                  <a href="https://create.ufuon.com/identify" target="_blank" >
                                    <p style={{marginBottom:'5%'}}>Forgot your password?</p> 
                                  </a>
                                  
                                <a href="/auth/signup" target="_blank">
                                   Don't have an account? Sign up
                                </a>
                                
                          </div>
                    </div>
                 :
                  null
                }
              
              </div>
          </div>

            <div id="modal-js-example"  class={addClassModal?"modal  is-active": "modal"}>
             <div class="modal-background"></div>
              <div class="modal-content " style={{position:'relative',bottom:'10%',width:"30%"}}>
              <div className='eventEnter-body'>
              {
                show.page === 1?
                <div className='event-search-list'>
                  <a><i class="fas fa-times-circle" onClick={()=>closeModal()}></i></a>
                  <div class="field">
                            <div class={isLoaded ?"control is-large is-loading": "control is-large "}>
                                <input class="input is-large" type="text"
                                onChange={(val) => textFirstnameChange(val.target.value)}
                                placeholder="Enter code"/>
                            </div>
                        </div>
                        {
                          error?
                          <p style={{textAlign:'center', color:'red', fontSize:'17px' ,marginBottom:'5px', marginTop:1}}>    {message}</p> 
                          :
                          null
                      }
                        {
                            show.page === 1?
                            <div>
                            {
                              isLoaded ? 
                              null
                              :
                              <button className="code-buttom" onClick={()=>SubmitCodeData()}>Submit</button>
                            }
                            
                          </div>
                          :
                          null
                        }
                </div>
                :
                <div>
                   <div className='event-search-code'>
                                  <a><i class="fas fa-arrow-left" onClick={()=>closeData()}></i></a>
                               
                                  {
                                    lesson === null ||  lesson === undefined?
                                      null
                                    :
                                    <div>
                                        {
                                          lesson.event === null  || lesson.event === undefined?
                                            null
                                              :
                                           
                                                <div className='event-addLink'>
                                              
                                                  <h3> {lesson.event.title}</h3> 
                                          
                                                  {
                                                    includesArray.includes(userId) ?
                                                     <h2> You are already in this class</h2> 
                                                  :
                                                  null
                                                  }
                                                  
                                                </div>
                                             
                                         }
                                    </div>
                                   
                                  }

                            </div>
                            {
                              includesArray.includes(userId) ?
                              <div className="event-clostButton">
                                  <button class="button is-danger" onClick={()=>closeModal()}>Closs </button>
                              </div>
                              :
                              null
                            }

                            {
                              includesArray.includes(userId) ?
                               null
                              :
                              <div>
                                  <br/>
                                 
                                  
                                    {
                                    loading ? 
                                    <div className="event-clostButton">
                                      <button class="button is-loading">Loading</button>
                                      </div>
                                      :
                                      
                                      <button className="code-buttom" onClick={()=>SubmitAdd(lesson.event._id)}>Add To Class</button>
                                    }
                                    
                            </div>
                            }
                           
                </div>
              }
                    
                  
              </div> 
              </div>
           </div>
          
      </div>
    </React.Fragment>
  );
}

export default Event;

// RA3NEPR
