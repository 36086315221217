import React, { useState } from "react"
// import { PaystackButton } from "react-paystack"
import { PaystackButton } from "react-paystack"
import "./paystack.css"


const Paystack = (props) => {
  const publicKey = "pk_test_1007e9b8ddb07fc05a17864b53865c135a948fbe"
  const URL_EXAM_END = "https://exam-end.herokuapp.com";
  const LOCAL_EXAM_END = "http://localhost:4000"
  const amount = 1000000
  const [email, setEmail] = useState(null)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const componentProps = {
    email:props.email,
    amount:props.amount,
    metadata: {
      name:props.name,
    //   phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: (res) =>

      callBackend(res),
    //   alert("Thanks for doing business with us! Come back soon!!"),
    onClose: () => alert("Wait! Don't leave :("),
  }

  const config = {
    reference: (new Date()).getTime().toString(),
    email: props.email,
    amount:  props.amount, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: publicKey
};

  const callBackend= (res)=>{
    console.log('callBackend---------->',res)
         const CreateData = {
           reference: res.reference,
        //    user:userId,
           student:true,
           request:true,
           classes: props.subject,
           typeOnline: 'Laptop/PC/Tablet',
           typeOfclass:props.state,
           firstname:props.firstName,
           lastname:props.lastName,
           social:props.found,
           phone:props.phone,
           email:props.email,
          //  found:props.email,
           subject:props.subject,
           plan:'Basic',
         }
         console.log('data---------successful------>',CreateData)
        //  if(data.data.event === 'successful'){
           console.log('data---------successful------>',res.reference)
        const requestOptions = {
                 method: 'POST',
                 headers: { 'Content-Type': 'application/json' },
                 body: JSON.stringify(CreateData),
              };
                 fetch(`${LOCAL_EXAM_END}/transactions/initialize/verify/${res.reference}`, requestOptions)
                        .then(res => res.json())
                        .then((res)=>{
                            if(res){
                              if(res.status === true){
                                  console.log('data-------------------->',res)
                                  // setPage({page:4,title:'payment'})
                                  // setStatus(true)        
                        }
                           
                    }
                         
                }).catch( (error) => {               
        })

  }
  // const initializePayment = usePaystackPayment(config);


  return (
    
    <div className="paystack-body">
    <h1>You Are Almost Done</h1>
      {
        console.log('props.amount',props.amount)
      }
      <div className="rig-paymet-body">

          <div className="rig-paymet-sub1">
            <h4>STEP ONE</h4>
            <h2><b>Complete payment</b></h2>
            <p><b>Make payment to access full access to app benefits</b></p>

            <PaystackButton {...componentProps} />

               <a> Talk to support</a>
          </div>
          <div className="rig-paymet-sub2">
             <img src="https://res.cloudinary.com/codepally/image/upload/v1706270755/bank-card_1_tiexlx.png" />
          </div>
         
      </div>
        
  
        
    </div>
  )
}

export default Paystack