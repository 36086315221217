import React, {Component,useState,useRef,useEffect} from 'react';
import AuthService from "../../services/auth.service";
import { truncateText } from "truncate-strings";
import axios from 'axios';
import {Route, Link, Routes, useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Assig = (props) => {
    const [percentage, setPercentage] = useState(0);
    const [progress,   setProgress] = useState(null);
    const [inProgress,setInProgress]= useState(false);




 const  download =(url, filename)=> {
    setInProgress(true)
 
    return fetch(`${url}`, {
       headers: new Headers({
         "Accept": "application/octet-stream"
       })
     })
     .then((response) => {
       return response.blob()
     })
     .then(blob => {
       var url = window.URL.createObjectURL(blob);
       var a = document.createElement('a');
       a.href = url;
       a.download = `${filename}`;
       document.body.appendChild(a);
       a.click();
       a.remove();
   
      setInProgress(false)
    })
    .catch(console.error);
  }
 


    return(
        <a class={inProgress? 'assignment-book-loading':`assignment-book `}  onClick={()=>download(`https://ucarecdn.com/${props.data.document.version_id}/${props.data.document.name}`,props.data.document.name)}> 
           <div >
           {
              inProgress ?
              <progress class="progress is-small is-primary" max="100">15%</progress>
              :
              <div class=" iaUqVE">
             
                    {
                        props.data.image === null ||  props.data.image === undefined ?
                       <img src="https://res.cloudinary.com/codepally/image/upload/v1687165394/UFUON%20LOGO%20PNG/img1_ma5tz7.png" alt="" class="jDhsfY"/>
                       :
                       <img src={props.data.image.url} alt="" class="jDhsfY"/>
                    }
              
                 
               </div>
           }
             
                 <h3>  { truncateText( props.data.title, {word: true, limit: 8, after: true} )}</h3>
               
              </div>
        </a>
     )
  










}

export default Assig;