import React, { useState } from "react"
import { Divider,  } from './components';
import RightContent from './components/RightContent';
import Slider from "../../components/caro/Slide";
import Images from "../../components/caro/image";
import './index.css';



const Register = (props) => {
    const [show, setShow] = useState(false)
   

    const ShowPayment = ()=>{
        setShow(true)
    }


    return (
        <div className="fullbody">
        {
        show ===false ?
          <div>
                <div class="start">
                    <h1>Find the right tutor for you.</h1>
                    <p>With over 30,000 tutors and 1M+ learners, we know language learning.</p>
                </div>

                <Slider images={Images} />
                <div class="reg-how">
                <h1>How Preply works:</h1>
                <div class="res-triangle">
                    <div class="triangle">
                        <h1>1</h1>
                        <h2>Find your tutor.</h2>
                        <p>We’ll connect you with a tutor who will 
                            <br/>motivate, challenge, and inspire you.</p>
                
                                <div class="rectangle">

                                    <div class="rectangle-sub">
                                        <div class="flex">
                                            <div class="res-name">
                                                <h3>Milena</h3>
                                            </div>
                                            <div class="reg-font">
                                                <i class="fa-solid fa-star"></i>
                                                <p>4.9</p>
                                                
                                        </div>
                                        </div>
                                        <div class="cap">
                                            <i class="fa-solid fa-graduation-cap"></i>
                                            <p>French tutor</p>
                                        </div>
                                        <div class="language">
                                            <i class="fa-solid fa-language"></i>
                                            <p>Speaks French (Native), 
                                                <br/>English (Advanced) +2</p>
                                        </div>
                                    </div>
                                
                                    <img class="english" src="https://res.cloudinary.com/dghjdfdfz/image/upload/v1706007406/ufuon%20code%20image/english_woman_tvmtzd.avif"/>
                                
                                </div>
                            <div class="rectangle-1">
                                
                                <div class="rectangle-sub">
                                    <div class="flex">
                                        <div class="res-name">
                                            <h3>Milena</h3>
                                        </div>
                                        <div class="reg-font">
                                            <i class="fa-solid fa-star"></i>
                                            <p>4.9</p>
                                            
                                    </div>
                                    </div>
                                    <div class="cap">
                                        <i class="fa-solid fa-graduation-cap"></i>
                                        <p>French tutor</p>
                                    </div>
                                    <div class="language">
                                        <i class="fa-solid fa-language"></i>
                                        <p>Speaks French (Native), 
                                            <br/>English (Advanced) +2</p>
                                    </div>
                                </div>
                                <img class="english-man" src="https://res.cloudinary.com/dghjdfdfz/image/upload/v1706007405/ufuon%20code%20image/english_man_vjtdwe.avif"/>
                            </div>
                
                            <div class="rectangle-2">
                                <div class="rectangle-sub">
                                    <div class="flex">
                                        <div class="res-name">
                                            <h3>Milena</h3>
                                        </div>
                                        <div class="reg-font">
                                            <i class="fa-solid fa-star"></i>
                                            <p>4.9</p>
                                            
                                    </div>
                                    </div>
                                    <div class="cap">
                                        <i class="fa-solid fa-graduation-cap"></i>
                                        <p>French tutor</p>
                                    </div>
                                    <div class="language">
                                        <i class="fa-solid fa-language"></i>
                                        <p>Speaks French (Native), 
                                            <br/>English (Advanced) +2</p>
                                    </div>
                                </div>
                            <img class="english-woman" src="https://res.cloudinary.com/dghjdfdfz/image/upload/v1706007406/ufuon%20code%20image/english_m9dzo0.avif"/>
                        </div>
                    </div>

                    <div class="triangle">
                        <div class="triangle2">
                            <h1>2</h1>
                            <h2>Start learning.</h2>
                            <p>Your tutor will guide the way through your first 
                                <br/>motivate, challenge, and inspire you.</p>

                                <img src="https://preply.com/cdn-cgi/image/format=auto,fit=contain,width=700/https://static.preply.com/static/ssr/_next/static/images/card-2-da929e1032468274fff3c7a827157232.jpg"/>
                        </div>
                    
                    </div>

                    <div class="triangle">
                        <div class="triangle3">
                            <h1>2</h1>
                            <h2>Speak. Read. <br/>Write. Repeat..</h2>
                            <p>Your tutor will guide the way through your first 
                                <br/>motivate, challenge, and inspire you.</p>

                                <img src="https://preply.com/cdn-cgi/image/format=auto,fit=contain,width=700/https://static.preply.com/static/ssr/_next/static/images/card-3-0bab46dd6b35951f6fc2e87968b6e3ea.jpg"/>
                        </div>
                    </div>

                </div>
        
                </div>

                    <div className='reg-lession'>
                        <h1><b>Lessons you’ll love. Guaranteed. </b> </h1>
                        <h4>Try another tutor for free if you’re not satisfied.         </h4>
                        <div className='reg-lession-sub'>
                            <button class="button is-rounded" onClick={()=>ShowPayment()}>Get started
                                <span class="icon is-small" style={{marginLeft:'10px'}}>
                                <i class="fa-solid fa-arrow-right" style={{marginLeft:'7px'}}></i>
                                </span>
                            </button>
                        </div>
                        

                    </div>
             </div>
             :
             <div className='subbody'>
                    <RightContent />
             </div>

        }

         
       
      </div>
    )
  
}

export default Register;
