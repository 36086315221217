export default [
    {
        src: 'https://res.cloudinary.com/codepally/image/upload/v1686744742/bfb12b40-32b3-48a7-a4a7-4d283af7e940_xbmlhw.jpg',
        text: 'Manjojo Logitics  ',
        text1 : "My Name Mrs Chioma Evueh. Ufuon online class is great opportunity for children learn after school curriculum . ",
        text2 : 'Mrs Chioma Evueh.',
        text3 : 'Parents'
    },
    // {
    //     src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqZ1TC9XBhtD0Iebrv9deoQc6zm5j0I4AOLA&usqp=CAU',
    //     text: 'Manjojo Logitics  ',
    //     text1 : "Ufuon Has really changed my son. Academically he doesn't play and forget reading his books anymore; rather, he takes quizzes  ",
    //     text2 : 'Joseph Ezenwa',
    //     text3 : 'Parents'
    // },
    {
     src: 'https://res.cloudinary.com/codepally/image/upload/v1685975648/UFUON%20LOGO%20PNG/MISS._IFY_tsckxh.png',
     text: 'Lucky Joe Investments Limited',
     text1:  'Thanks to Ufuon app, getting a part time Home lesson Job was made easy. I submitted my CV on the app and the job offers came in very fast ',
     text2:  'Maureen',
     text3:  'Tutor'
    },
    {
     src: 'https://res.cloudinary.com/codepally/image/upload/v1685973352/UFUON%20LOGO%20PNG/MR._SAM_pjhcmu.png',
     text: 'Rexhot',
     text1 : 'Thanks to Ufuon App i am able to sell my books as pdf and also get paid as my book is viewed ',
     text2 : 'Samuel lawson',
     text3 : 'Tutor'
    }
]