import React, {Component,useState,useRef,useEffect} from 'react';
import AuthService from "../../services/auth.service";
import { truncateText } from "truncate-strings";
import axios from 'axios';
import {Route, Link, Routes, useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Assig from './assinloop.js'

// import {API_BASE_URL,URL_EXAM_END, API_BASE_LOCAL,ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import './publicAssin.css';


const PublicAssignment = (props) => {
   // const id = props.match.params;
   const params = useParams();
   const URL_EXAM_END = "https://exam-end.herokuapp.com";
   const URL_LOCAL = "http://localhost:4000";
//   const currentUser = AuthService.getCurrentUser();
  const [userId,setUserId] = useState(null)
  const [publish,setPublish]= useState(false);
  const [errorColor,setErrorColor] =useState(false);
  const [AlertNotice ,setAlertNotice]=useState(false);
  const [loadPost,setLoadPost]=useState(false);
  const [alertMessage ,setAlertMessage]=useState(null);
  const [isLoaded ,setIsLoaded]=useState(false);
  const [hideModal,setHideModal]=useState(false);
  const [assignments,setAssignments]= useState([])
  const [books,setBooks]= useState([])
  const [assignmentData,setAssignmentData]= useState(null)
  const [assignment,setAssignment]= useState(null)
  const [lessonModal,setLessonModal]=useState(false);
const [lessons,setLessons]= useState([])
  const [bookModal,setBookModal]=useState(false);
  const [show,setShow] = useState({page:1,title:'Summary'})
  const [lessonData,setLessonData] = useState(null)
 const [confirm,setConfirm] = useState({page:1,title:'show data'})
 const [confirmBook,setConfirmBook] = useState({page:1,title:'show data'})
 const [bookData,setBookData] = useState(null)
 const [deleteBook,setDeleteBook]= useState(null)
 const [loading , setLoading]= useState(false)
 const [loadingPage,setLoadingPage]= useState(false)
 const [quizShow,setQuizShow] = useState({page:1,title:'show data'})
 const [modal,setModal]= useState(true)
 const [nameBookError, setNameBookError ] = useState(false);
 const [message,setMessage] = useState(''); 
 const [messageNameBook, setMessageNameBook] = useState(null);
 const [error,setError] = useState(false); 
 const [value, setValue] = React.useState("");
 const [typyOf, setTypeOf]= useState(false)
 const [DataEnter,setDataEnter]= useState('Student')
 const [currentUser, setCurrentUser] = useState(undefined);

   
  useEffect(()=> {
   setLoadingPage(true)
   setUserId(params.id)
   const fetchData = async () => {
      const user = AuthService.getCurrentUser();

      if (user) {
         setCurrentUser(user);
         setModal(false)
      
       }
       fetch(`${URL_EXAM_END}/assignment/${params.id}`)
       .then(res => res.json())
       .then(
         (res) => {
            //   console.log("log---------------->",res)
             if(res.status === false){
               // setIsLoaded(false);
               setLoadingPage(false)
             }else{
               setAssignment(res.data)
               setLoadingPage(false)
             }

         },
         (error) => {
           // setIsLoaded(false);
           // setError(error);
         } )
   }  
   fetchData();
},[])



const showBookDelete = (data) =>{
    setBookData(data)
    setDeleteBook(true)
    setConfirmBook({page:1,title:'show data'})
}



const getDayOfTheMon =(data)=>{
   var dayName =['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
   var day = dayName[new Date(data).getDay()];
   // console.log("first one------>",new Date())
   // console.log("second one------>",new Date(data).getDay())
     return day
 }

 const getMonth =(data)=>{
    var day = parseInt(data) - 1
   //  console.log("give me day----------->",day)
   var month 
   var dayName =['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];
   //  console.log("month-------------->",dayName[day] )
   return month =  dayName[day]

 }

 const formatAMPM =(date) => {
   var hours = date.getHours();
   var minutes = date.getMinutes();
   var ampm = hours >= 12 ? 'pm' : 'am';
   hours = hours % 12;
   hours = hours ? hours : 12; // the hour '0' should be '12'
   minutes = minutes < 10 ? '0'+minutes : minutes;
   var strTime = hours + ':' + minutes + ' ' + ampm;
   return strTime;
 }
   

 const textFirstnameChange =  (val) => {
   // setNameBook
   if( val.length !== 0 ) {
     setValue(val)
       const reg = /^(\d*)(\.(\d*))?$|^$/
       if (reg.test(val) === true){
        
           setNameBookError(true)
           setError(true)
           setMessage('Input not  valid')
       }
       else{
          
            setNameBookError(false)
            setMessageNameBook(null)
            setError(false)
         setMessage('Input not  valid')
       }
 
   } else {
     setValue(val)
   }
 }

 const SubmitCodeUser = () =>{
   setIsLoaded(true)
   //   console.log("data ----USER---->",)
     if(value === '' || nameBookError === true ||value === null || value.length === 0 ){
  
         setNameBookError(true)
         setMessageNameBook('Name not  valid')
         setError(true)
         setMessage('Input not  valid')
         setIsLoaded(false)
         return
     }
       const data = {
       code:value
     }
     const requestOptions = {
      method: 'POST',
        headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
        //   'x-access-token': Auth.getToken()
      },
      body: JSON.stringify(data)
      };

     fetch(`${URL_EXAM_END}/api/user/code`, requestOptions)
     .then(res => res.json())
     .then(res => {
     
       if(res.status  ===true){
         setIsLoaded(false)
         // console.log('Error:---------------=--------------->', res);
            AuthService.loginWithCode(res.data)
            setQuizShow({page:1,title:'Summary'})
         setModal(false)
       }else{
         setIsLoaded(false)
         // setErrorCode(true)
         toast("We didn't recognize that code PIN. Please check and try again.")
         timeout()
       }
     }) .catch((error) => {
   console.error('Error:', error);
   setIsLoaded(false)
   toast("We didn't recognize that code PIN. Please check and try again.")
  });

 }



  const timeOUT =()=>{
    setTimeout(function(){
      // navigate("/dashboard");
      window.location.reload();
   }, 1900);
  }


 const SubmitCodeStudent = ()=>{
   setIsLoaded(true)
   // console.log("data ----true-judge-article----->",user)
   if(value === '' || nameBookError === true ||value === null || value.length === 0 ){

       setNameBookError(true)
       setMessageNameBook('Name not  valid')
       setError(true)
       setMessage('Input not  valid')
       setIsLoaded(false)
       return
   }
     const data = {
     code:value
   }
   const requestOptions = {
    method: 'POST',
      headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
      //   'x-access-token': Auth.getToken()
    },
    body: JSON.stringify(data)
    };

   fetch(`${URL_EXAM_END}/api/student/code/`, requestOptions)
         .then(res => res.json())
         .then(res => {
         
         if(res.status  ===true){
            setIsLoaded(false)
     
            // setLesson(res.data)
            // toast("Save successfully ")
      
            AuthService.loginWithCode(res.data)
            setQuizShow({page:1,title:'Summary'})
            setModal(false)
         }else{
            setIsLoaded(false)
            // setErrorCode(true)
            toast("We didn't recognize that code PIN. Please check and try again.")
            timeout()
         }
         }) .catch((error) => {
      console.error('Error:', error);
      setIsLoaded(false)
      toast("We didn't recognize that code PIN. Please check and try again.")
    });
}
 
 const timeout=() =>{
   setTimeout(function() {
   //   setErrorCode(false)
   //   console.log(this.state.noticeshow);
   }.bind(this), 4000);
 }


const  handleType= (e) => {
   // console.log("----=-true-------->",e.target.value )
      if(e.target.value === 'User'){
         setTypeOf(false)
         setDataEnter('User')
         // console.log("----=-true-------->",e.target.value )
         setQuizShow({page:2,title:'show data'})
         }else{
         // console.log("----=---false------>",e.target.value )
         setDataEnter('Student')
         setTypeOf(true)
         setQuizShow({page:2,title:'show data'})
      }
 }



 const downloadData = (url,filename) => {
   // fetch(url)
   //   .then(response => response.blob())
   //   .then(blob => {
   //     const link = document.createElement("a");
   //     link.href = URL.createObjectURL(blob);
   //     link.download = filename;
   //     link.click();
   // })
   // .catch(console.error);

}



    return(
      loadingPage?
      <div className="assignment-full">
         <div className="assignment-loading">
            <button class="button is-loading">Loading</button>
         </div>
      </div>
      :
      <div className="code-full assignmentClass-body">
      <ToastContainer />
         <div class="columns " >
            <div class="column is-three-quarters">
               <div className='assignmentClass-left-hander'>
              
                  <h2><b>Report</b></h2>
                  {
                     assignment === null?
                     null:
                     <h4><b>{assignment.name}</b></h4>
                  }
                
                  {
                     assignment === null?
                     null
                     :
                     <div class="tabs assignmentClass-tab">
                        
                           <ul>
                              <li class={show.page === 1 ? "is-active" : null}><a onClick={()=>setShow({page:1,title:'Summary'})}>Summary</a></li>
                              <li class={show.page === 3 ? "is-active" : null}><a  onClick={()=>setShow({page:3,title:'Questions'})}>Questions({assignment.question.length})</a></li>
                              <li class={show.page === 4 ? "is-active" : null}><a  onClick={()=>setShow({page:4,title:'Books'})}>Books({assignment.books.length})</a></li>
                           </ul>
                     </div>
                  }
               </div>
            </div>
            <div class="column">
            {
               assignment === null? 
               null
               :
               <div className="assignment-left-body">
                     <h4>Assigned Ufuon</h4>
                     {
                        assignment.done  === true? 
                        <button class="button is-danger is-outlined is-small" >
                              <span>Finish </span>
                        </button>
                        :
                        <button class="button is-primary is-outlined is-small">On Going</button>
                        
                     }
                     
                     {
                        assignment === null?
                        null
                        :
                        <div >
                        {
                           assignment.createdAt === null || assignment.createdAt === undefined?
                            null
                           :
                           <div class='assignment-left-time'>
                           <p> Start date: {getDayOfTheMon(assignment.createdAt)}, {getMonth(new Date(assignment.createdAt).getMonth())}, {formatAMPM(new Date(assignment.createdAt))}</p>
                           </div>
                        }
                        
                        
                        </div>
                     }
                     {
                        assignment === null?
                        null
                     :
                     <div >
                     {
                        assignment.end === null ||  assignment.end === undefined?
                        null
                        :
                        <div class='assignment-left-time'>
                        <p> End date: {getDayOfTheMon(assignment.end.day)}, {getMonth(new Date(assignment.end.day).getMonth())}, {formatAMPM(new Date(assignment.end.hour))}</p>
                        </div>
                     }
                        
                        
                     </div>
                     }
                  
                     {
                        assignment === null?
                        null
                        :
                        <div class=''>
                           Hosted by {assignment.user.firstname} {' '} {assignment.user.lastname}
                        </div>
                     }
               </div>
            }
            </div>
         </div>
         {
         show.page === 1?
         <div>
            <div className='assignmentClass-summary'>
                  <div class="columns">
                     <div class="column is-half">
                        <div className='assignment-summary-left'>
                        <div className='assignment-difficult-sub'>
                           <div className='assignment-difficult-head'>
                           <h2>Difficult questions(0)</h2>
                        </div>
                     <p>Great job! No one found any questions too challenging.</p>  
                     </div>
                     </div>
                     </div>
                     <div class="column">
                     {
                        assignment === null?
                        null
                        :
                        <div className='assignment-summary-left'>
                           <div className='assignment-summary-details'>
                           <h2>Assignment details</h2>
                           </div>   
                         
                           <div className='assignment-summary-details'>
                            {
                               assignment.event === null  ||   assignment.event === undefined ? 
                               null
                               :
                               <h3> <i class="fas fa-link" style={{color:'#864cbf',fontSize:'20px',marginRight:'3%'}}></i> {assignment.event.title}</h3>
                            }
                             
                           </div>
                        
                           <div className='assignment-summary-details'>
                           
                           <h5>{   assignment.students.length}</h5>
                              <h3> <i class="fas fa-user-graduate" style={{color:'#864cbf',fontSize:'20px',marginRight:'3%'}}></i> Students</h3>
                           </div>
                           <div className='assignment-summary-details'>
                           <h5>{assignment.player.length}</h5>
                              <h3><i class="fas fa-user" style={{color:'#864cbf',fontSize:'20px',marginRight:'3%'}}></i> Players</h3>
                           </div>
                           <div className='assignment-summary-details'>
                           <h5>{assignment.question.length}</h5>
                              <h3> <i class="fas fa-question-circle" style={{color:'#1368ce',fontSize:'20px',marginRight:'3%'}} ></i> Questions </h3>
                     
                           </div>
                        </div>
                     }
                     </div>
                    <div class="column">
                      <div className='assignment-image'> 
                        <img src='https://res.cloudinary.com/codepally/image/upload/v1680262778/UFUON%20LOGO%20PNG/INQUISITIVE_KID_pdxlfl.png'
                        width={'100%'} style={{height:130}} />
                     </div>     
                  </div>
               </div>
             </div>
           </div>
          :
          null   
         }
       
          {
             show.page === 2 ?  
              <div className='assignmentClass-player'>
                <h2>All Students</h2>
               {
                  assignment === null  ||     assignment === undefined?
                  null
                  :
                  <table class="table  is-bordered assignment-table">
                        <thead>
                           <tr>
                              <th><abbr title="Position">  Fullname</abbr></th>
                              <th>Rank</th>
                             
                        
                           </tr>
                        </thead>
                  
                        <tbody>
                        {
                           assignment.students.map(item => {
                               return(
                                 <tr>
                                    <th style={{width:'40%'}}><strong>{item.firstname } {' '} {item.firstname}</strong></th>
                                    <td><a href="#" title="Leicester City F.C.">{item.firstname } {' '} {item.firstname}</a> <strong></strong>
                                    </td>
                                  
                                 </tr>
                              )})
                           }
                           
                        </tbody>
                  </table>
               }
                  
               <h2>All Plays</h2>
                  {
                     assignment === null  ||     assignment === undefined?
                     null
                     :
                     <table class="table  is-bordered assignment-table">
                           <thead>
                              <tr>
                                 <th><abbr title="Position">  Fullname</abbr></th>
                                 <th>Rank</th>
                              
                           
                              </tr>
                           </thead>
                     
                           <tbody>
                           {
                              assignment.player.map(item => {
                                 return(
                                    <tr>
                                       <th style={{width:'40%'}}><strong>{item.firstname } {' '} {item.firstname}</strong></th>
                                       <td><a href="#" >{item.firstname } {' '} {item.firstname}</a> <strong></strong>
                                       </td>
                                       
                                    </tr>
                                 )})
                              }
                              
                           </tbody>
                     </table>
                  }
              </div>
              :
              null
          }
     
          {
             show.page === 3 ?
             <div className='assignmentClass-questions'>
             <h2>All Questions</h2>

                  {
                     assignment === null  ||     assignment === undefined?
                     null
                     :
                     <div>
                     {
                        assignment.question .length === 0 ?
                          <div class="content has-text-grey has-text-centered assPublicAss">
                              <p><span class="icon is-large"><i class="mdi mdi-emoticon-sad mdi-48px"></i></span></p>
                              <p>No Assignment</p>
                           </div>
                           :
                       <div>
                       {
                        assignment.question.map(item => {
                        return(
                         <div>
                           <div className='assignment-quiz-head'>
                              {
                                 item.title
                              }
                              </div>
                              <div className='assignment-quiz-body'>
                              <table class="table  is-bordered assignment-table">
                                 <thead>
                                    <tr>
                                       <th><abbr >   Question</abbr></th>
                                       <th>Type</th>
                                       
                                    
                                    </tr>
                                 </thead>
                              
                                 <tbody>
                                    {
                                    item.questions.map(itemData => {
                                    return(
                                          <tr>
                                             <th style={{width:'90%'}}>
                                                {itemData.question}
                                             </th>
                                             <td>
                                                {itemData.typeof}
                                             </td>
                                             
                                             
                                          </tr>
                                    )})
                                 }
                                 
                                    
                                 </tbody>
                           </table>
                           </div>
                         </div>
                       
                        )})
                     }
                       </div>
                     }
                    </div>
                  }
               </div>
          :
          null
          }

          {
            show.page === 4 ?
            <div className='assignment-questions'>
                  {
                     assignment.books.length === 0 ?
                     
                        <div class="content has-text-grey has-text-centered assPublicAss">
                           <p><span class="icon is-large"><i class="mdi mdi-emoticon-sad mdi-48px"></i></span></p>
                           <p>No Books…</p>
                        </div>
                   
                     :
                     <div class="main-list">
                    
                          
                              
                           {
                              assignment.books.map(item => {
                                    return(
                                         <Assig  data={item}/>
                                       
                                    )
                                 })
                              }
                    
                     
                      
                    </div>
              }
                     
            </div>
          :
          null
          }

         
            
          {
            assignment === null? 
            null
            :
            <div className="assignment-mobile-body">
                  <h4>Assigned Ufuon</h4>
                  {
                     assignment.done  === true? 
                     <button class="button is-danger is-outlined is-small" >
                           <span>Finish </span>
                     </button>
                     :
                     <button class="button is-primary is-outlined is-small">On Going</button>
                     
                  }
                  
                  {
                     assignment === null?
                     null
                     :
                     <div >
                     {
                        assignment.end === null || assignment.end === undefined?
                        <div class='assignment-left-time'>
                        <p> Create date: {getDayOfTheMon(assignment.createdAt)}, {getMonth(new Date(assignment.createdAt).getMonth())}, {formatAMPM(new Date(assignment.createdAt))}</p>
                        </div>
                        :
                        <div class='assignment-left-time'>
                        <p> End date: {getDayOfTheMon(assignment.end.day)}, {getMonth(new Date(assignment.end.day).getMonth())}, {formatAMPM(new Date(assignment.end.hour))}</p>
                        </div>
                     }
                     
                     
                     </div>
                  }
                  {
                     assignment === null?
                     null
                  :
                  <div >
                  {
                     assignment.end === null ||  assignment.end === undefined?
                     null
                     :
                     <div class='assignment-left-time'>
                     <p> End date: {getDayOfTheMon(assignment.end.day)}, {getMonth(new Date(assignment.end.day).getMonth())}, {formatAMPM(new Date(assignment.end.hour))}</p>
                     </div>
                  }
                     
                     
                  </div>
                  }
               
                  {
                     assignment === null?
                     null
                     :
                     <div class=''>
                        Hosted by {assignment.user.firstname} {' '} {assignment.user.lastname}
                     </div>
                  }
               </div>
             }

             
            
             <div id="modal-js-example"  class={modal?"modal  is-active": "modal"}>
             <div class="modal-background"></div>
               <div class="modal-content" style={{position:'relative',bottom:'10%'}}>
               {
                 quizShow.page === 1?
                 null
                 :quizShow.page === 2?
                     <button  onClick={()=> setQuizShow({page:1,title:'show data'})} class="button is-link is-rounded" style={{float:'left',width:'10%',top:'10%',position:'relative',left:'5%'}}>
                       <span class="icon is-small">
                         <i class="fas fa-arrow-left"></i>
                         </span>
                     </button>
                     :
                     null
             }
              <div class="assinmentPu-image">
                 <img alt='sce' src="https://res.cloudinary.com/codepally/image/upload/v1676975708/UFUON%20LOGO%20PNG/Ufuon_ordinary_qxmvqp.png" />
              </div>
            {
               quizShow.page === 1?
                  <div class="box assinmentPu-modal-head">
                     <h3>Select user</h3>
                        <div >
                           <div class="field">
                               <p class="control has-icons-left">
                                <div class="control has-icons-lef is-expanded"  >
                                      <div class="select is-large is-fullwidth">
                                         <select onChange={handleType}  >
                                             <option>Select   Account</option>
                                            <option label={'Student'}  value={'Student'} >Student</option>
                                            <option label={'User'}  value={'User'}>User</option>
                                         </select>
                                      </div>
                                      <span class="icon is-large is-left">
                                        {
                                          typyOf ?
                                          <i class="fas fa-user-graduate"></i>
                                          :
                                          <i class="fas fa-user"></i>
                                        }
                                       
                                      </span>
                                </div>
                               </p>
                           </div>
                       </div>
                 </div>
              :
               quizShow.page === 2 ?
          
                   <div class="box assinmentPu-modal-head">
                     <h3> Get your {DataEnter } code from profile in  </h3>
                         <h3> your UFUON mobile app </h3>
                       <div className="assigment-modal-title">
                           <div class={isLoaded ?"control is-large is-loading": "control is-large "}>
                           <input class="input is-large" type="text"
                           onChange={(val) => textFirstnameChange(val.target.value)}
                           placeholder="Enter Your Profile code" style={{textAlign:"center"}}/>
                         </div>
                       </div>
                      {
                        typyOf ?
                        <button className="code-buttom" style={{marginTop:'3%'}} onClick={()=>SubmitCodeStudent()}>Submit</button>
                        :
                        <button className="code-buttom" style={{marginTop:'3%'}} onClick={()=>SubmitCodeUser()}>Submit</button>
                          
                        
                      }
                      

                    </div>
               :
               null


            }
         
           </div>
         </div>




         </div> 
        

    )
  
}

export default PublicAssignment;



