import dayjs from "dayjs";
import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
// import GlobalContext from "../context/GlobalContext";

export default function Day({ day, rowIdx, myClass,allClass}) {
  // console.log('log-------Day------------->',day,"row",rowIdx)
  const [dayEvents, setDayEvents] = useState([]);
  const [MyDayEvents, setMyDayEvents] = useState([]);
  // const { setShowEventModal } = useContext(GlobalContext);
  const {
    setDaySelected,
    setShowEventModal,
    filteredEvents,
    filteredEventsMyClass,
    setSelectedEvent,
  } = useContext(GlobalContext);

  useEffect(() => {
    const events = filteredEvents.filter(
      (evt) =>
        dayjs(evt.start.day).format("DD-MM-YY") === day.format("DD-MM-YY")
    );
    setDayEvents(events);
  }, [filteredEvents, day]);


  useEffect(() => {
    // console.log('------------my lesson-----student------->',filteredEventsMyClass)
    const events = filteredEventsMyClass.filter(
      (evt) =>
        dayjs(evt.start.day).format("DD-MM-YY") === day.format("DD-MM-YY")
    );
    // console.log('thus os me=---------------->',events)
    setMyDayEvents(events);
  }, [filteredEventsMyClass, day]);

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-blue-600 text-white rounded-full w-7"
      : "";
  }
  return (
    allClass === true ?
    <div className="border border-gray-200 flex flex-col " style={{height:'150px'}}>
      <header className="flex flex-col items-center">
        {rowIdx === 0 && (
          <a   onClick={() =>{
            setDaySelected(day);
            setShowEventModal(true)
          } }>
          <p className="text-sm mt-1">
            {day.format("ddd").toUpperCase()}
          </p>
          </a>
        )}
        <a   onClick={() =>{
          setDaySelected(day);
          setShowEventModal(true)
        }}>
          <p className={`text-sm p-1 my-1 text-center  ${getCurrentDayClass()}`} >
            {day.format("DD")}
          </p>
        </a>
      </header>
      <div
        className="flex-1 cursor-pointer"
        onClick={() => {
          setDaySelected(day);
          setShowEventModal(true);
        }}
      >
        {dayEvents.map((evt, idx) => (
          <div
            key={idx}
            onClick={() => setSelectedEvent(evt)}
            className={`bg-${evt.label}-200 p-1 mr-3 text-gray-600 text-sm rounded mb-1 truncate`}
          >
            {evt.title}
          </div>
        ))}
      </div>
    </div>
    :myClass === true ?
    <div className="border border-gray-200 flex flex-col " style={{height:'150px'}}>
    <header className="flex flex-col items-center">
      {rowIdx === 0 && (
        <a   onClick={() =>{
          setDaySelected(day);
          setShowEventModal(true)
        } }>
        <p className="text-sm mt-1">
          {day.format("ddd").toUpperCase()}
        </p>
        </a>
      )}
      <a   onClick={() =>{
        setDaySelected(day);
        setShowEventModal(true)
      }}>
        <p className={`text-sm p-1 my-1 text-center  ${getCurrentDayClass()}`} >
          {day.format("DD")}
        </p>
      </a>
    </header>
    <div
      className="flex-1 cursor-pointer"
      onClick={() => {
        setDaySelected(day);
        setShowEventModal(true);
      }}
    >
      {MyDayEvents.map((evt, idx) => (
        <div
          key={idx}
          onClick={() => setSelectedEvent(evt)}
          className={`bg-${evt.label}-200 p-1 mr-3 text-gray-600 text-sm rounded mb-1 truncate`}
        >
          {evt.title}
        </div>
      ))}
    </div>
  </div>
  :
  null
  );
}
