import React, { useRef } from 'react';
import useSlider from '../../utils/hooks/useSlider'

const Slider = ({images}) => {
    
    const slideImage = useRef(null)
  
    const slideText1 = useRef(null)
    const slideText2 = useRef(null)
    const slideText3 = useRef(null)
    const { goToPreviousSlide, goToNextSlide } = useSlider(slideImage,
     slideText1,
     slideText2, 
     slideText3,
     images)

    return (

          <div class="reg-flex-caro">
             <div class="allow-flex">
                        <div class="beauty" ref={slideImage} >
                        </div>
                        <img class="briana" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTTpbq3UDnKnFw6dBOByHK-Dk3sXcmMeEi4Q&usqp=CAU"/>
                         <div class="res-text">

                             <h1 ref={slideText1}></h1>
                            
                             <h2 ref={slideText2} ></h2>
                             <p ref={slideText3} ></p>
                      
                         <div className="home-slide-arrow">
                            <a onClick={goToPreviousSlide} >
                                <img src="https://res.cloudinary.com/codepally/image/upload/v1621205973/codepally%20website/homeright_vw2eio.svg" />
                            </a>
                            <a onClick={goToNextSlide} >
                              <img src="https://res.cloudinary.com/codepally/image/upload/v1621205969/codepally%20website/homeleft_xr6li9.svg" />
                            </a>
                        </div>     
                       
                    </div>
             </div>
     </div>
           

          
    );
}

export default Slider;