import React, {Component,useState,useRef,useEffect,useCallback} from 'react';
import AuthService from "../../services/auth.service";
import { truncateText } from "truncate-strings";

import {Route, Link, Routes, useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDropzone } from "react-dropzone"
import { useNavigate } from "react-router-dom";
import axios from 'axios';
// import {API_BASE_URL,URL_EXAM_END, API_BASE_LOCAL,ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import './assignments.css';
import './main.table.css'

const Assignments = (props) => {
   // const id = props.match.params;
   const params = useParams();
   const URL_EXAM_END = "https://exam-end.herokuapp.com";
  const currentUser = AuthService.getCurrentUser();
  const [userId,setUserId] = useState(null)
  const [publish,setPublish]= useState(false);
  const [errorColor,setErrorColor] =useState(false);
  const [AlertNotice ,setAlertNotice]=useState(false);
  const [loadPost,setLoadPost]=useState(false);
  const [alertMessage ,setAlertMessage]=useState(null);
  const [isLoaded ,setIsLoaded]=useState(false);
  const [assignment,setAssignment]= useState(null)
  const [lessonModal,setLessonModal]=useState(false);
  const [lessonData,setLessonData] = useState(null)
  const [name,setName] = useState(null)
  const [assignments,setAssignments]= useState([])
  const [assignmentModal,setAssignmentModal]= useState(false)
  const [step,setStep]= useState({page:1,title:'show data'})
  const [examId, setExamId] = useState(null);
  const [title , setTitle] = useState(null)
  const [titleBookError , setTitleBookError] = useState(null)
  const [message , setMessage] = useState(null)
  const [error , setError] = useState(null)
  const [loadPublish,setLoadPublish]= useState(false);
  const navigate = useNavigate();

  
 
   
  useEffect(()=> {
//    setLoadingPage(true)
   // props.error404(false)
   // props.updateTitle('course')
   // props.error404(false)
   
   setExamId(uuidv4(5))
   setUserId(params.id)
   setUserId(currentUser._id)
   setLoadPublish(true)
   const fetchData = async () => {
       fetch(`${URL_EXAM_END}/assignments/user/${currentUser._id}?student="true"`)
       .then(res => res.json())
       .then(
         (res) => {
    
             if(res.status === true){
               // setIsLoaded(false);
         
               setAssignments(res.data)
               setLoadPublish(false)
             }else{
              setLoadPublish(false)
             
             }

         },
         (error) => {
           // setIsLoaded(false);
           // setError(error);
      })

           
         
  }  
   fetchData();
},[])

const uuidv4=(digits)=> {
 
  let str = '0123456789abxyzABCDEFGHIJKLMNOPQRSTUVXZ';
  let uuid = [];
  for (let i = 0; i < digits; i++) {
      uuid.push(str[Math.floor(Math.random() * str.length)]);
  }
  return uuid.join('');
  }
  
  

const getDayOfTheMon =(data)=>{
  var dayName =['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var day = dayName[new Date(data).getDay()];
  // console.log("first one------>",new Date())
  // console.log("second one------>",new Date(data).getDay())
    return day
}

const getMonth =(data)=>{
   var day = parseInt(data) - 1
  //  console.log("give me day----------->",day)
  var month 
  var dayName =['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];
  //  console.log("month-------------->",dayName[day] )
  return month =  dayName[day]

}

const formatAMPM =(date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


const textTitle =  (val) => {
  // setNameBook
  if( val.length !== 0 ) {
    setTitle(val)
      const reg = /^(\d*)(\.(\d*))?$|^$/
      if (reg.test(val) === true){
    
          setTitleBookError(true)
          setError(true)
          setMessage('Input not  valid')
      }
      else{
      
           setTitleBookError(false)
           // setMessageNameBook(null)
           setError(false)
        setMessage('Input not  valid')
      }

  } else {
     setTitle(val)
  }
}

const Allassignments =()=>{
  setStep({page:1,title:'Allassignments'})
}

const assignmentsDone = ()=>{
  setStep({page:2,title:'Allassignments'})
}

const SubmitData = ()=>{
  setIsLoaded(true)
    if(title === ''  || titleBookError === true ||title === null || title.length === 0 ){
 
        setTitleBookError(true)
         setError(true)
         setMessage('Input not  valid')
         toast("Input not  valid")
      return
    }
       const dataInTake ={
         name:title,
         examId:'RA'+uuidv4(5),
         user:currentUser._id,
         quiz:null
       }
       const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify(dataInTake),
       };
       console.log("requestOptions",dataInTake)
       // assignmentAll.question.push(dataInTake)
  
       fetch(`${URL_EXAM_END}/assignment-web/new`, requestOptions)
       .then(res => res.json())
         .then((res)=>{
             console.log("res", res)
               if(res.status === true){
              
                timePush(res.data)
                 }else {
                  setIsLoaded(false)
               
                 }
             }).catch((error) => {
              setIsLoaded(false)
               console.error('Error:', error);
       });
}



const timePush =(data)=>{
  setTimeout(function(){

    setIsLoaded(false)
    navigate(`/assignment/${data._id}`);
    window.location.reload();
 }, 1000);
}


    return(
      loadPublish?
      <div className="assignment-full">
         <div className="assignment-loading">
            <a class="button is-loading">Loading</a>
         </div>
      </div>
      :
      <div className="assignment-full">
          <div className='assignments-sub'>
             <div>
              <div className='assignments-add'>
                  <button class="button is-success jb-modal" onClick={()=>setAssignmentModal(true)} data-target="sample-modal" type="button">
                      <span class="icon"><i class="fas fa-plus"></i></span>
                  </button>
              </div>
                 <div className='assignments-input'>
                     <input class="input is-rounded" type="text" placeholder="Search"/>
                 </div>
                
             </div>
             <div className='assignments-table'>
                 
                 <div className='hoKSwH'>
                     <a onClick={()=>Allassignments()}>
                         <div className={step.page === 1?'assignments-tableTitle active-data':"assignments-tableTitle"}>
                          <p>All (5)</p> 
                        </div>
                     </a>
                    
                     <a onClick={()=>assignmentsDone()}>
                        <div className={step.page === 2?'assignments-tableAssig active-data':'assignments-tableAssig'}>
                            <p>Assignments (5)</p> 
                        </div>
                     </a>
                </div>
                <div className='hoKSwH'>
                   
                     <div>
                          <div class="b-table">
                            <div class="table-wrapper has-mobile-cards">
                              <table class="table is-fullwidth is-striped is-hoverable is-fullwidth">
                                <thead>
                                <tr>
                                  
                                  <th style={{width:'45%'}}>Name</th>
                                  <th>Date</th>
                                  <th>Game mode	</th>
                                  <th>Players</th>
                                  <th></th>
                                </tr>
                                </thead>
                                <tbody>

                                  {
                                    assignments.map(item => {
                                      return(
                                            <tr>
                                               
                                                
                                                <td data-label="Name" style={{width:'40%'}}> {item.name}	</td>
                                              
                                                  {
                                                    item.end === null || item.end === undefined?
                                                     <td data-label="City"> {getDayOfTheMon(item.createdAt)}, {getMonth(new Date(item.createdAt).getMonth())}, {formatAMPM(new Date(item.createdAt))}</td>
                                                     :
                                                     <td data-label="City">  Start: {getDayOfTheMon(item.createdAt)}, {getMonth(new Date(item.createdAt).getMonth())}, {formatAMPM(new Date(item.createdAt))}</td>
                                                  }
                                                <td data-label="Created">
                                                    <small class="has-text-grey is-abbr-like" title="Oct 25, 2020">Assigned Ufuon</small>
                                                </td>
                                                <td data-label="Created" style={{    textAlign: 'center'}}>
                                                    <small class="has-text-grey is-abbr-like" >
                                                    ({item.player.length + item.students.length})
                                                    </small>
                                                    </td>
                                                    <td class="is-actions-cell">
                                                    <div class="buttons is-right">
                                                    <Link to={`/assignment/${item._id}`} class="button is-small is-primary" type="button">
                                                        <span class="icon"><i class="mdi mdi-eye"></i></span>
                                                    </Link>
                                                    </div>
                                                </td>
                                          </tr>
                                        )
                                    })
                                  }
                                  </tbody>
                                </table>
                            </div>
                          
                        </div>
                     </div>
                    
                </div>
             </div>
             <div id="modal-js-example"  class={assignmentModal?"modal  is-active": "modal"}>
               <div class="modal-background"></div>
               <div class="modal-content" style={{position:'relative',bottom:'20%'}}>
                            <div class="box">
                                 <div className="assigment-modal-title">
                                     <input class="input is-info" type="text" placeholder="Enter name"
                                     onChange={(val) => textTitle(val.target.value)}
                                     />
                                     {
                                      titleBookError?
                                      <p style={{textAlign:'left', color:'red', fontSize:'13px' ,marginBottom:'5px', marginTop:'5px',marginLeft:'1%'}}>    {message}</p> 
                                      :
                                      null
                                     }

                                     {
                                      isLoaded ?
                                      <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',marginTop:'4%'}}>
                                            <button class="button is-white   is-loading" >Loading</button>
                                      </div>
                                      :
                                      <div className='assigment-modal-bottom' style={{ justifyContent: 'center',textAlign:'center',marginTop:'4%'}}>
                                      <button class="button btn-default  is-danger is-small" onClick={(e)=>setAssignmentModal(false)}>
                                      Close
                                      </button>
                                            <button class="button is-primary is-outlined is-small"  onClick={(e)=>SubmitData()}>Submit</button>
                                         
                                      </div> 
                                   } 
                                </div>
                            </div>
                        </div>

                    <button class="modal-close is-large" aria-label="close"  onClick={()=>setAssignmentModal(false)}></button>
                </div>
          </div>
       </div>

    )
  
}

export default Assignments;



