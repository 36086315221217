import React, { useContext, useState,useEffect } from "react";
import GlobalContext from "../context/GlobalContext";
import dayjs from "dayjs";
const labelsClasses = [
  "indigo",
  "gray",
  "green",
  "blue",
  "red",
  "purple",
];

export default function EventModal() {
  const [dayEvents, setDayEvents] = useState([]);
  const [showMore, setShowMore] = useState(false)
  const {
    setShowEventModal,
    daySelected,
    dispatchCalEvent,
    filteredEvents,
    selectedEvent,
  } = useContext(GlobalContext);
  var today = new Date();
  useEffect(() => {
    const events = filteredEvents.filter(
      (evt) =>
        dayjs(evt.day).format("DD-MM-YY") === daySelected.format("DD-MM-YY")
    );
    setDayEvents(events);
  }, [filteredEvents, daySelected]);

  const [title, setTitle] = useState(
    selectedEvent ? selectedEvent.title : ""
  );
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );

  const [link, setLink] = useState(
    selectedEvent ? selectedEvent.link : ""
  );

  const [start, setStart]= useState(
    selectedEvent ? selectedEvent.start : ""
  );
  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((lbl) => lbl === selectedEvent.label)
      : labelsClasses[0]
  );
   

  const handleSlide =()=>{
    setShowMore(!showMore)
  }

  function handleSubmit(e) {
    e.preventDefault();
    const calendarEvent = {
      title,
      description,
      label: selectedLabel,
      day: daySelected.valueOf(),
      id: selectedEvent ? selectedEvent.id : Date.now(),
    };
    if (selectedEvent) {
      dispatchCalEvent({ type: "update", payload: calendarEvent });
    } else {
      dispatchCalEvent({ type: "push", payload: calendarEvent });
    }

    setShowEventModal(false);
  }

  const formatAMPM =(date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
 

  const isToday = (dateToCheck) => {
    // Get today's date
    const today = new Date();
 
    // Compare the components of the dateToCheck with today's date
    const isSameDate =
        dateToCheck.getDate() === today.getDate() &&
        dateToCheck.getMonth() === today.getMonth() &&
        dateToCheck.getFullYear() === today.getFullYear();
 
    // Return true if the dateToCheck is today, otherwise return false
    return isSameDate;
};

  return (
    
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center items-center">

      <div className={showMore ?  "bg-white rounded-lg shadow-2xl w-2/4":"bg-white rounded-lg shadow-2xl w-1/4"}>
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
          <span className="material-icons-outlined text-gray-400">
            drag_handle
          </span>
          <div>
           <a style={{marginRight:'5px'}} onClick={()=>handleSlide()}>
              <span class="material-icons-outlined  text-gray-400 ">
              visibility
              </span>
           </a>
            <button onClick={() => setShowEventModal(false)}>
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
          </div>
        </header>
        {showMore ? 
          <div className="p-3">
        
          <div className="grid grid-cols-1/5 items-end gap-y-7">
          {dayEvents.map((evt, idx) => (
            <div
              key={idx}
            
              className={`bg-${evt.label}-200 p-1 mr-3 text-gray-600 text-sm rounded mb-1 truncate`}
            >
              {evt.title}
            </div>
          ))}
          </div>
        
        </div>
        :
           <div className="p-3"> 
              <div className="grid grid-cols-1/5 items-end gap-y-7">
                    <div></div>
                       <p  className="pt-3 border-0 text-gray-600 text-l font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500">{title}</p>
                        <span className="material-icons-outlined text-gray-400">
                          schedule
                        </span>
                      <p>{daySelected.format("dddd, MMMM DD")}  Time: {formatAMPM(new Date(start.hour))}</p>
                      <span className="material-icons-outlined text-gray-400">
                        school
                      </span>
                      
                      <a href={`${link}`} target="_blank"> 
                        {link}  
                      </a>
                      
                    </div>

                    {dayEvents.map((evt, idx) => (
                      
                      
                        isToday(new Date(evt.start.day))?
                        <div
                            key={idx}
                            className={`bg-${evt.label}-200 p-1 mr-3 text-gray-600 text-sm rounded mb-1 truncate`}  style={{marginTop:"15px"}} >
                          
                        
                         
                            {evt.title} 
                            
                          
                          </div>
                      
                        :
                        null
                      
                  ))}
              <br/>
            </div>
        }
    
       
      </div>

    
    </div>
  );
}
