import React, { useState, useRef,useEffect } from "react";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import ReactCodeInput from "react-code-input";
import axios from "axios";
import DatePicker from "react-datepicker";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput ,{ formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
// import "./App.css";
import { getMonth } from "../../utils/util";
import CalendarHeader from "../calendar/CalendarHeader";
import AuthService from "../../services/auth.service";
import Sidebar from "../calendar/Sidebar";
import Month from "../calendar/Month";
import GlobalContext from "../context/GlobalContext";
import EventModal from "../calendar/EventModal";
import OTP from "./otpInput";
import "react-datepicker/dist/react-datepicker.css";
import "./Login.css"
const required = (value) => {
  
    if (!value) {
      return (
        <div className="invalid-feedback d-block">
          This field is required!
        </div>
      );
    }
  };

  const CORRECT_PIN_CODE = "111222";

  
function Login() {
  const URL_EXAM_END = "https://exam-end.herokuapp.com";
  const URL_LOCAL = "http://localhost:4000"; 

    const form = useRef();
    const checkBtn = useRef();
  
    // useEffect(()=> {
    //   const user = AuthService.getCurrentUser();
       
    //   if (user) {
    //      timePush()
    //   }
  
    // }, []);
  
    const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [chooseData, setChooseData] = useState("");
  const [getType, setType]= useState(null)
  const [step,setStep] = useState({page:1,title:'show data'})
  const currentDate = new Date();
  const [codeInfo,setCodeInfo] = useState({page:1,title:'show data'})
  const [myMonth, setMyMonth] = useState(currentDate);
  const [myYear, setMyYear] = useState(currentDate);
  const [myDay, setMyDay] = useState(currentDate);
  const [value, setValue] = useState()
  const [invalid,setInvalid]=useState(false);
  const minDate = new Date(myYear.getFullYear(), myMonth.getMonth(), 1);
  const maxDate = new Date(myYear.getFullYear(), myMonth.getMonth() + 1, 0);
  const [languageBookError, setLanguageBookError] = useState(null)
  const [description,setDescription] = useState(null)
  const [descBookError,setDescBookError] = useState(false)
  const [authorsBookError ,setAuthorsBookError] = useState(false)
  const [titleBookError,setTitleBookError]= useState(false)
  const [error,setError]= useState(false)
  const [userID,setUserID]=useState(null);
  const [isLoaded ,setIsLoaded]=useState(false);
  const [school, setSchool]=useState(null);
  const [schoolError,setSchoolError]=useState(null);
  const [lastname,setLastname]=useState(null);
  const [lastnameError,setLastnameError]=useState(null);
  const [firstNameError,setFirstNameError]=useState(null);
  const [firstName,setFirstName]=useState(null);
  const [age,setAge]=useState(null);
  const [birthday,setBirthday]=useState(null);
  const [gender,setGender]=useState(null);
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [btnIsPressed, setBtnIsPressed] = useState(false);

  
  useEffect(() => {
    setMyDay(new Date(myYear.getFullYear(), myMonth.getMonth(), 1));
  }, [myMonth, myYear, setMyDay]);

  const renderDayContents = (day, date) => {
    if (date < minDate || date > maxDate) {
      return <span></span>;
    }
    return <span>{date.getDate()}</span>;
  };
  
    const navigate = useNavigate();
  
    const onChangeUsername = (e) => {
      const username = e.target.value;
      setUsername(username);
    };
  
    const onChangePassword = (e) => {
      const password = e.target.value;
      setPassword(password);
    };


    const handleLogin = async (e) => {
      e.preventDefault();
      setMessage("");
      setLoading(true);
  
      form.current.validateAll();
      //  console.log('login----------------------->',username, password)
      if (checkBtn.current.context._errors.length === 0) {
              return axios
          .post(URL_EXAM_END + "/api/login", {
            username,
            password,
          })
          .then((response) => {
            // console.log("users----------user------data-->",response.data)
            if (response.data.status === "true") {
              // callUserData(response.data)
              // console.log("users----------user------data-->",response.data)
              axios.get(URL_EXAM_END + `/users/${response.data.data.id}`, {
              })
              .then((response) => {
                // console.log("users----------user------data-->",response.data)
                if (response.data.status === true) {
                
                  localStorage.setItem("user", JSON.stringify(response.data.data));
                  // setUserID(res.data)
                  AuthService.loginWithCode(response.data.data)
                  AuthService.loginGetId(response.data.data)
                  AuthService.loginGetTokon(response.data.data.token)
                  timeOUT()
                  return response.data.data
                }
              });
            }else{
              setLoading(false);
              // console.log('login--------------<>', response.data.message)
              setMessage(response.data.message.message);
            }

          });
        
      } else {
        setLoading(false);
      }
    };
  

    function getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
      return age;
  }
  
  
    const timeOUT =()=>{
      // console.log('login---------')
      setTimeout(function(){
        navigate("/class");
        window.location.reload();
     }, 1900);
    }
  
  
  
    const timePush =()=>{
      setTimeout(function(){
        navigate("/class");
        window.location.reload();
     }, 1000);
    }
  
  
    const NextData  = async (data ,step) =>{
      setType(data)  
      let obj = {  
          data: data,  
          step: step +1,  
          position:data
        }  
        setChooseData(data)
        setStep({page:3,title:'Choose'})
    }

   const moveToPhone = async (data ,step) =>{
      console.log( 'myYear',myYear.getFullYear(), 'myMonth',myMonth.getMonth(),'myDay',myDay.getDay())
      var d = new Date(myYear.getFullYear(), myMonth.getMonth(), myDay.getDay());
        // console.log('give me day--------->',d, getAge(d))
        setBirthday(d)
        setAge(getAge(d))
        setStep({page:3,title:'Choose'})
    }

    const NextStudent = async (data ,step) =>{
      setType(data)  
      let obj = {  
          data: data,  
          step: step +1,  
          position:data
        }  
        setChooseData(data)
        setStep({page:2,title:'Choose'})
    //  console.log("data-----1-------->",data)
    }

    const textDescription =(val) => {
      // setNameBook
      if( val.length !== 0 ) {
        setDescription(val)
          const reg = /^(\d*)(\.(\d*))?$|^$/
          if (reg.test(val) === true){
              setDescBookError(true)
              setError(true)
              setMessage('Input not  valid')
          }
          else{
   
               setAuthorsBookError(false)
               // setMessageNameBook(null)
               setError(false)
            setMessage('Input not  valid')
          }
    
      } else {
         setDescription(val)
      }
    }

    const StudentData = ()=>{
      if(!isValidPhoneNumber(value)){
          setInvalid(true)
          return
      }
      const data = {
        phone:value,
      }
      const requestOptions = {
        method: 'POST',
          headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
          //   'x-access-token': Auth.getToken()
        },
          body: JSON.stringify(data)
        };
        // setCodeInfo({page:2,title:'show data'})
      fetch(`${URL_EXAM_END}/students/verification/`, requestOptions)
      .then(res => res.json())
      .then(res => {
        console.log('Error:---------------=--------------->', res);
        if(res.status  ===true){
            setIsLoaded(false)
            setCodeInfo({page:2,title:'show data'})
            setUserID(res.data)
        }else{
            toast("We didn't recognize that code PIN. Please check and try again.")
          //  setIsLoaded(false)
          // setErrorCode(true)
          //  toast("We didn't recognize that code PIN. Please check and try again.")
          //  timeout()
        }
      }) .catch((error) => {
    console.error('Error:', error);
    //  setIsLoaded(false)
    //  toast("We didn't recognize that code PIN. Please check and try again.")
    });
}


  const StudentCodeSubmit = ()=>{
    // console.log('login----------->',description)
          const data = {
            code:description,
            student:userID
          }
      const requestOptions = {
        method: 'POST',
          headers: { 'Content-Type':'application/json','Access-Control-Allow-Origin': '*' ,
          //   'x-access-token': Auth.getToken()
        },
          body: JSON.stringify(data)
        };
        fetch(`${URL_EXAM_END}/api/student/code`, requestOptions)
        .then(res => res.json())
        .then(res => {
        
          if(res.status  ===true){
            setIsLoaded(false)
            // console.log('status:---------------=--------------->', res);
            // timeOUT()
                        AuthService.loginWithCode(res.data)
                        AuthService.loginGetId(res.data)
                        AuthService.loginGetTokon(res.token)
                        timeOUT()
              
                }else{
                  toast("Wrong OTP")
              }
           }) .catch((error) => {
      // console.error('Error:', error);
    });
  }

  const  handleTerm = (e) => {
    // console.log("----=--------->",e.target.value )     
    setGender(e.target.value) 
 }


 const checkPinCode = () => {
  const isPinCodeValid = pinCode === CORRECT_PIN_CODE;

  setBtnIsPressed(true);
  setIsPinCodeValid(isPinCodeValid);
  if (!isPinCodeValid) setPinCode("");
};

const handlePinChange = pinCode => {
  setPinCode(pinCode);
  // setBtnIsPressed(false);
};

const inputOtpData = (data)=>{
  // console.log("inputOtpData ----------->data",data)
}

const formik = useFormik({
  
});


    return (

           <div>
           <ToastContainer />
             <div className="">
                <br/>
                {
                  step.page === 1 ?
                  <div  className="loginChooseFullBody">
                     <h2> <b>Which account</b></h2>
                        <div className="loginChooseBody">
                          <a onClick={() => {NextData("Professional",3)}}>
                              <div className="loginChoose loginChooseHover">
                                  <div className="loginChoose-subProfessional "></div>
                                    <div className="loginChoose-font Professional">
                                      <i class="fas fa-user-tie"></i>
                                    </div>
                                    <div className="loginChoose-title Professional">
                                        <p><b>Professional</b> </p>
                                    </div>
                                </div>
                              </a>
                                <a onClick={() => {NextStudent("Student",1)}}>
                                <div className="loginChoose loginChooseHoverStudent">
                                    <div className="loginChoose-subStudent "></div>
                                      <div className="loginChoose-font Student">
                                      <i class="far fa-user"></i>
                                      </div>   

                                    <div className="loginChoose-title Student">
                                        <p><b>Student</b> </p>
                                    </div>
                                </div>
                              </a>
                        </div>
                    </div>
                 : step.page === 2?
                  <div className="loginChooseFullBody">
                  {
                    codeInfo.page === 1?
                    <h2><b>Enter phone number</b> </h2>
                    :
                    null
                  }
                
                    <div className="signupPhone">
                    {
                         codeInfo.page === 1?
                         <div className="event-form">
                         
                      
                           <label class="label"> Phone </label>
                           <div class="control">
                           <PhoneInput
                                 placeholder="Enter phone number"
                                 error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                 defaultCountry="NG"
                                 value={value}
                                 onChange={setValue}/>
                             
                           </div>

                           {
                             invalid === true ?
                             <div className="eventModal-p">
                                 <p> Number invalid</p> 
                               </div>
                               :
                             null
                           }
                           <br/>
                          
                               <div  style={{textAlign:'center',position:'relative',bottom:'7%'}} onClick={()=>StudentData()}>
                                   <button class="nav-class-buttom mobile903" > 
                                   Submit
                                 </button>
                               </div>
                         </div>  
                         : codeInfo.page === 2?
                         <div className="event-form">
                            <form  onSubmit={formik.handleSubmit}>
                                <h2 style={{textAlign: "center"}}><b>Confirm phone number</b></h2>
                              <label style={{textAlign: "center"}} class="label"> Enter the OTP we sent to your phone
                              </label>
                              <br/>
                              <p style={{textAlign: "center"}}>
                                <OTP inputOtpData={inputOtpData} userID={userID}/>
                              </p>

                              <br/>
                              <a style={{float:"right",textAlign: "right",position:"relative",left:"1%"}}>  Change number</a>
                              <a> Resend OTP  </a> 
                                <br/>
                                <br/>
                              <div  style={{textAlign:'center'}} onClick={()=>StudentCodeSubmit()}>
                               
                              </div>
                              <br/>
                              <p> Need have an account? Sign up</p>
                          </form>
                         </div>  
                         :null
                       }
                  
                  </div>
                  </div>
                   
                  : step.page === 3?
                  <div className="login-div">
                    <div className="login-logo">
                          <p>
                              <img alt='sce' src="https://res.cloudinary.com/codepally/image/upload/v1676975708/UFUON%20LOGO%20PNG/Ufuon_ordinary_qxmvqp.png"  style={{height:60,width:'40%'}}/>
                          </p>
                             
                             </div>
                               <div className="card ">
                               <img
                               src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                               alt="profile-img"
                               className="profile-img-card"
                             />
                     
                               <Form onSubmit={handleLogin} ref={form}>

                              
                                 <div className="form-group">
                                   <label htmlFor="username">Email </label>
                                   <Input
                                     type="text"
                                     className="form-control"
                                     name="username"
                                     value={username}
                                     onChange={onChangeUsername}
                                     validations={[required]}
                                   />
                                 </div>

                            
                       
                                 <div className="form-group">
                                   <label htmlFor="password">Password</label>
                                   <Input
                                     type="password"
                                     className="form-control"
                                     name="password"
                                     value={password}
                                     onChange={onChangePassword}
                                     validations={[required]}
                                   />
                                 </div>
                       
                                 <div className="form-group">
                                   <button className="btn btn-primary btn-block" disabled={loading}>
                                     {loading && (
                                       <span className="spinner-border spinner-border-sm"></span>
                                     )}
                                     <span>Submit</span>
                                   </button>
                                 </div>
                       
                                 {message && (
                                   <div className="form-group">
                                     <div className="alert alert-danger" role="alert">
                                       {message} 
                                     </div>
                                   </div>
                                 )}
                                 <CheckButton style={{ display: "none" }} ref={checkBtn} />
                               </Form>
                     
                             </div>
                             <div className="login-forget">
                                 <a href="/identify">Forgot your password?</a>
                       </div>
                 </div>
                 :
                 null

                }
             
              </div>
              <p style={{textAlign: 'center',marginTop:'5%',color:"#000"}}>
                 <b>
                     Don't have an account? 
                        <a href="/auth/signup" style={{textAlign: 'center',marginTop:'5%',color:"red",textDecoration:"none"}}>  Sign up
                      </a>
                  </b>
              </p>
           </div>
    )







}

export default Login;