import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import Slider from 'react-rangeslider'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-rangeslider/lib/index.css'
// import { connect } from 'react-redux';
// import { ActionCreators } from '../../../../actions/profile';
// import { InputRange, MultiSelect } from '../InputRange';
import stateList from '../mock/state.json';
import Paystack from '../paystack/paystack'
import { formatPhoneNumber, isValidEmail } from '../../../../utils';
import './style.css';

const data1 = [

  { id: 3, txt: 'Electronics', isChecked: false },
  { id: 10, txt: 'Coding', isChecked: false },
  { id: 6, txt: 'Science', isChecked: false },
  { id: 7, txt: 'Mathematics', isChecked: false },
  { id: 8, txt: 'Economics', isChecked: false },
  { id: 9, txt: 'History', isChecked: false },
  { id: 11, txt: 'Language', isChecked: false },
  { id: 12, txt: 'Art', isChecked: false },
   { id: 16, txt: 'Chemistry', isChecked: false },
   { id: 18, txt: 'Christian Religious Studies', isChecked: false },
   { id: 19, txt: 'Food and nutrition', isChecked: false },
   { id: 22, txt: 'Social studies', isChecked: false },
   { id: 24, txt: 'Physics', isChecked: false },
   { id: 27, txt: 'Islamic religious Studies', isChecked: false },
   { id: 28, txt: 'Integrated Science', isChecked: false },
   { id: 29, txt: 'Igbo', isChecked: false },
   { id: 31, txt: 'Yoruba', isChecked: false },
   { id: 32, txt: 'Verbal', isChecked: false },
   { id: 33, txt: 'Quantitative', isChecked: false },

 
];

const found = [
    
  { id: 1, txt: 'Instagram', isChecked: false },
  { id: 2, txt: 'Facebook', isChecked: false },
  { id: 3, txt: ' Veronica Daughter', isChecked: false },
  { id: 4, txt: 'Referred by a friend', isChecked: false },
  { id: 5, txt: 'Other', isChecked: false },


];

export class RightContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstName: '',
        lastName: '',
        telephone: '',
        age: 28,
        email: '',
        state: '',
        country: '',
        address: 'Home',
        
        address1: '',
        address2: '',
        interests: [],
        subscribenewsletter: false,
      },
      volume: 0,
      page : 1,
      subject:[],
      FoundData:[],
      checkedItems: new Map() ,
      checkedFound: new Map(),
      address1: '',
      amount : null,
    
      errors: {
        user: {
            firstName: 'Enter First Name',
            telephone: 'Enter Telephone',
            email: 'Email is not valid!',
            address1: 'Enter address1',
            address2: 'Enter address2',
            interests: 'Enter your Interests'
          }
       },
      validForm: false,
      submitted: false
    }
    this.handleChange = this.handleChange.bind(this);  
    this.handleFound = this.handleFound.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this); 
  }

  componentDidMount() {
    if(this.props.profile) {
      this.setState({ user: this.props.profile });
      if (this.props.profile.email) {
        this.resetErrorMsg();
      }
    }
  }

  validationErrorMessage = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    // console.log('-first---------->',value,name)
    switch (name) {
      case 'firstName': 
        errors.user.firstName = value.length < 1 ? 'Enter First Name' : '';
        break;
      case 'lastName': 
        errors.user.lastName = value.length < 1 ? 'Enter Last Name' : '';
        break;
      case 'email': 
        errors.user.email = isValidEmail(value) ? '' : 'Email is not valid!';
        break;
      case 'telephone': 
        errors.user.telephone = value.length < 1 && value.length > 10 ? 'Enter valid telephone number' : '';
        break;
      case 'address1': 
        errors.state.address1 = value.length < 1 ? `Enter ${this.state.address1} address1` : '';
        break;
      default:
        break;
    }

    this.setState({ errors });
  }

  inputChange = (event) => {
    let telphone = ''
    // let address1 = ''
    
    const { name, value , } = event.target;
    const user = this.state.user;
    if (name === 'telephone') {
      telphone = formatPhoneNumber(value);
      user[name] = telphone;
    }else if(name === 'address'){
       this.setState({address1:value})
    }else {
      user[name] = value;
    }
    this.setState({ user });
    this.validationErrorMessage(event);
  }

  checkboxChange = (event) => {
    const { name, checked } = event.target;
    const user = this.state.user;
    user[name] = checked;
    this.setState({ user });
  }

  onChangeAddress = (event) => {
    const user = this.state.user;
    user['address1'] = event.target.value;
    this.setState({ user });
  }

  onChangeInputRange = (value) => {
    const user = this.state.user;
    user['age'] = value;
    this.setState({ user })
  }

  onSelectedInterest = (value) => {
    const user = this.state.user;
    const errors = this.state.errors;
    user['interests'] = value;
    errors.user.interests = value.length < 1 ? 'Enter your Interests' : '';
    this.setState({ user, errors });
  }

  validateForm = (errors) => {
    let valid = true;
    Object.entries(errors.user).forEach(item => {
      console.log(item)
      item && item[1].length > 0 && (valid = false)
    })
    return valid;
  }

  submitForm = async (event) => {
    // this.setState({ submitted: true });
    // this.props.dispatch(ActionCreators.formSubmittionStatus(true));
    // const user = this.state.user;
    // if (user && this.props.profile) {
    //   user.profileImage = this.props.profile.profileImage;
    // }
    // event.preventDefault();
    // if (this.validateForm(this.state.errors) && this.props.profile && this.props.profile.profileImage) {
    //   console.info('Valid Form')
    //   this.props.dispatch(ActionCreators.addProfile(user));
    //   this.props.history.push('/React-form-registration/confirm')
    // } else {
    //   console.log('Invalid Form')
    // }
  }

  handleOnChange = (value) => {
    this.setState({
      volume: value
    })
  }
 

  resetErrorMsg = () => {
    let errors = this.state.errors;
    errors.user.firstName = ''
    errors.user.telephone = ''
    errors.user.email = ''
    errors.user.address1 = ''
    errors.user.address2 = ''
    errors.user.interests = ''
    this.setState({ errors });
  }



  handleChange(event) {  
    console.log(this.state.checkedItems); 
    var isChecked = event.target.checked; 
    var item = event.target.value;  
       
    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) })); 
} 

handleFound(event) {  
  console.log(this.state.checkedFound); 
  var isChecked = event.target.checked;  
  var item = event.target.value;  
     
  this.setState(prevState => ({ checkedFound: prevState.checkedFound.set(item, isChecked) }));  
} 


  handleSubmit(event) { 
    event.preventDefault();  
    var data = []
    this.state.checkedItems.forEach((element,key) => 
    data.push({name:key,element:element})
    );
    this.setState({
        subject:data
      })
    console.log(data,'----------->',this.state.subject)
  } 
  
  NextPage = async (event) => {
    event.preventDefault();
        console.log('-checkedFound---------->',this.state.checkedFound)
        if(this.state.user.firstName .length < 1  ||  this.state.user.firstName  === null ) {
          toast("Question cant be empty")
             console.log('this.state.user.firstName ----------->',this.state.user.firstName )
           return
          }else if (this.state.user.lastName.length < 1   ||  this.state.user.lastName  === null ) {
            console.log('this.state.user.lastName ----------->',this.state.user.lastName)
            return
          }else if (!isValidEmail(this.state.user.email)  ||  this.state.user.email  === null ) {
            console.log('this.state.user.email ----------->',this.state.user.email)
            return
          }else if (this.state.volume === 0 ) {
            console.log('this.state.volume ----------->',this.state.volume)
            toast("Add your age ")
            return
          }else if (this.state.user.telephone.length < 1 && this.state.user.telephone.length > 10  || this.state.user.telephone  === null ) {
            console.log('this.state.user.email ----------->',this.state.user.email)
            return
          }else{

            var data = []
            var whereData = []
            this.state.checkedItems.forEach((element,key) => 
            data.push({name:key,element:element})
            );
            this.state.checkedFound.forEach((element,key) => 
            // console.log('data------->',key,)
               whereData.push({name:key})
            );
             this.setState({
                subject:data,
                FoundData:whereData
              })
            console.log(data,'----------->',this.state.subject,"FoundData",whereData)
            this.setState({
             page:2
           })
       }
   
  }

  NextType = async (event) => {
    event.preventDefault();
   
        
    if(this.state.user.state === "N20,000 one to one (12 class)"){
      this.setState({
        amount : 2000000,
      })
        }else if (this.state.user.state === "N40,000 one to one (15 class)") {
      this.setState({
        amount : 4000000,
      })
       } else if  (this.state.user.state === "N15,000 group (12 class)") {
      this.setState({
        amount : 1500000,
      })
      } else {
      
      
    }

    var data = []
    var whereData = []
    this.state.checkedItems.forEach((element,key) => 
    data.push({name:key,element:element})
    );
   
    this.state.checkedFound.forEach((element,key) => 
    // console.log('data------->',key,)
       whereData.push({name:key})
    );
     this.setState({
        subject:data,
        FoundData:whereData
      })

    console.log(data,'----------->',this.state.subject,"FoundData",whereData)
    if(whereData.length < 1 ) {
      toast("Please tell us how you found out about Ufuon")
       return
      }else if (data.length  < 1 ) {
        console.log('this.state.subject----------->',data.length )
        toast("T")
        return
      }else if (this.state.user.state === null || this.state.user.state === undefined) {
          toast("Please pick a 3 subject")
        return
      }else{
        this.setState({
          page:3
        })
      }
   
  }





  render() {
    const { firstName, lastName, age, email, telephone, state, country, address, address1, address2, interests, subscribenewsletter } = this.state.user;
    const { submitted,volume,page,subject ,amount } = this.state;
    const listState = stateList.listStates.map((item, key) =>
      <option key={key} value={item.name}>{item.name}</option>
    );
    return (
        <div>
           <ToastContainer />
            <div className='regstart-body'>
                  <div className='regstart-slide-imge'>
                  {
                    page === 1 ? 
                    <div className='slide-imge-rig'>
                      <img src="https://img.freepik.com/premium-photo/african-american-teacher-isolated_392895-16881.jpg" />
                    </div>
                    :page === 2 ?
                    <div className='slide-imge-rig'>
                      <img src="https://educatelagos.com.ng/secondary-sch/wp-content/uploads/sites/9/2021/02/Girl-Hero-Overlay.png" style={{marginTop:'5%'}}/>
                    </div>
                    :page === 3 ?
                    <div className='slide-imge-rig'>
                    <img style={{marginTop:'5%'}} src="https://www.digitaltheatreplus.com/hs-fs/hubfs/Untitled%20design%20-%202023-09-20T154725.131.png?width=800&height=800&name=Untitled%20design%20-%202023-09-20T154725.131.png" />
                   </div>
                   :
                   null

                  }
                 </div>
                <div className='regstart-slide-form'>
                 {
                   page === 1 ?
                    <div className="regstart-first">
                      <h2>To join the online class you will need the following</h2>
                      <div className="row row-coustom">
                        <label className="col-sm-2 col-form-label">Name</label>
                        <div className="col-sm-4 mb-2">
                          <input type="text" value={firstName} name="firstName" onChange={(e) => { this.inputChange(e)} } className="form-control" placeholder="First Name" />
                          {   <span className='res-error'>{this.state.errors.user.firstName} </span>}
                        </div>
                        <div className="col-sm-4 mb-2">
                          <input type="text" value={lastName} name="lastName" onChange={(e) => { this.inputChange(e)} } className="form-control" placeholder="Last Name" />
                          {   <span className='res-error'>{this.state.errors.user.lastName} </span>}
                        </div>
                        
                      </div>
                      <br/>
                      <div className="row row-coustom">
                        <label htmlFor="formControlAgeRange" className="col-sm-2 col-form-label">Age</label>
                            <div className="col-sm-7 mb-2">
                                <Slider
                                value={volume}
                                orientation="horizontal"
                                min={5}
                                max={50}
                                onChange={this.handleOnChange}
                              />
                          <p className='res-value'><b>{volume}</b></p>
                        </div>
                      </div>
                      <div className="row row-coustom">
                        <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                        <div className="col-sm-8 mb-2">
                          <input type="email" value={email} name="email" onChange={(e) => { this.inputChange(e)} } className="form-control" id="email" placeholder="itjebasuthan@gmail.com" />
                          { <span className='res-error'>{this.state.errors.user.email}</span>}
                        </div>
                        <div className="col-sm-4">
                        </div>
                      </div>
                      <br/>
                      <div className="row row-coustom">
                        <label htmlFor="telephone" className="col-sm-2 col-form-label">Tel</label>
                        <div className="col-sm-8 mb-2">
                          <input type="text" pattern="[0-9]" maxLength="14" value={telephone} name="telephone" onChange={(e) => { this.inputChange(e)} }  className="form-control" id="telephone" placeholder="(212)477-1000" />
                          {  <span className='res-error'>{this.state.errors.user.telephone}</span>}
                        </div>
                        <div className="col-sm-4">
                        </div>
                      </div>
                       <br/>
                      <div className="row row-coustom">
                      <label htmlFor="telephone" className="col-sm-2 col-form-label" style={{position:'relative',bottom:'15px'}}>City of Residence</label>
                      <div className="col-sm-8 mb-2">
                        <input type="text"  value={this.state.address1} name="address" onChange={(e) => { this.inputChange(e)} }  className="form-control" id="address" placeholder="City of Residence" />
                    
                      </div>
                      <div className="col-sm-4">
                      </div>
                    </div>
                     

                    <div className="row body-buttom">
                     
                        <button type="button"  className="button is-primary  is-rounded " onClick={this.NextPage}>Next</button>
                        <div className="col-sm-3"></div>
                    </div>
  
                      <br/>

                  </div>
                  : page === 2 ?
                        <div>
                        <div className="list-subject">
                          <p>Please pick a 3 subject </p>
                            <ul>
                                  {  
                                  data1.map(item => (  
                                      <li>  
                                      
                                        <label >  
                                        {item.txt}
                                          <input  
                                            type="checkbox"  
                                            class="checkbox"
                                            
                                            value={item.txt}  
                                            onChange={this.handleChange}  
                                          />   
                                        </label>  
                                      </li>  
                                    ))  
                                  }  
                            </ul>
                        </div>
                        <div className="row registar-class">
                        <p>we have one to one and group class which monthly</p>
                        <label htmlFor="staticEmail1" className="col-sm-2 col-form-label" style={{fontSize:'20px',position:'relative',bottom:'13px'}}>Class</label>
                        <div className="col-sm-6 mb-2">
                          <select className="custom-select" value={state} name="state" id="inlineFormCustomSelect" onChange={this.inputChange}>
                            {listState}
                          </select>
                        </div>
                        <div className="col-sm-4">
                        </div>
                      </div>
                        <div className='reg-found'>
                         <p>Please tell us how you found out about Ufuon</p>
                            <div className='list-found'>
                            <ul>
                                  {  
                                  found.map(item => (  
                                      <li>  
                                      
                                        <label >  
                                      
                                          <input  
                                            type="checkbox"  
                                            class="checkbox"
                                            
                                            value={item.txt}  
                                            onChange={this.handleFound}  
                                          />   
                                          {item.txt}
                                        </label>  
                                      </li>  
                                    ))  
                                  }  
                            </ul>
                          </div>
                      </div>
                        <div className="row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-6 mb-2">
                          <label htmlFor="subscribenewsletter">
                              <input type="checkbox" checked={subscribenewsletter} name="subscribenewsletter" onChange={this.checkboxChange} id="subscribenewsletter" style={{margin: '10px'}} />	
                              I have read and agree to the Terms and Conditions and Privacy Policy
                            </label>
                        </div>
                        <div className="col-sm-4">
                        </div>
                      </div>
                      <div className="row body-buttom">
                      
                        <button type="button"  className="button is-primary  is-rounded" onClick={this.NextType}>Next</button>
                        <div className="col-sm-3"></div>
                    </div>
                     </div>
                  
                  : page === 3 ?
                     <div className="">
                     
                       <Paystack email={email} phone={telephone} state={state} amount={amount}  subject={subject} found={this.state.FoundData} 
                       firstName={firstName} lastName={lastName} 
                       name={`${firstName }  ${ lastName}`}  />
                    </div>
                   :
                    null
                }
                
                
                </div>
            </div>

         
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.user.profile
  }
}

export default RightContent;
